.superContainerTopLevel {
  width: 75%;
  position: relative;
}
.superContainer {
  position: relative;
  border: 1px solid #389fff;
  overflow: hidden;
  border-radius: 14px;
}
.downloadButton {
  position: absolute;
  top: -3rem;
  right: 1rem;
  cursor: pointer;
  // color: rgb(42, 42, 42);
  color: #389fff;
  // font-size: 17px;
  font-weight: 500;
}
.downloadButtonIcon {
  margin-right: 0.5rem;
}
.containerTop {
  top: 0;
  width: 100%;
  height: 2.4rem;
  border-radius: 14px 14px 0 0;
  // position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: #389fff;
}
.container {
  padding: 1rem;
  max-height: 20rem;
  position: relative;
  // border-bottom: 1px solid #389fff;
  overflow: scroll;
}
.containerOpen {
  max-height: 40rem;
}
.resumePreviewContainer {
  height: 100%;
}
.containerBottom {
  bottom: 0;
  width: 100%;
  height: 2.4rem;
  border-radius: 0 0 14px 14px;
  // position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  cursor: pointer;
  color: #389fff;
}
.containerBottomIcon {
  margin-left: 0.25rem;
}
.resumeContainer {
  max-width: 25rem;
}
