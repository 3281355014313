.active_jobs {
  &__container {
    font-family: 'Poppins';
  }
  &__search {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    width: 20rem;
    padding: 0.25rem 0.5rem;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #fff;
    & input {
      border: none;
      width: calc(100% - 1rem);
      margin-right: 0.5rem;
      font-size: 1.1rem;
      &:focus {
        outline: none;
      }
    }
  }
  &__header {
display: flex;
gap: 20px;
align-items: center;
justify-content: space-between;
  }

  &__title {
    font-size: 1.7rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  &__row {
    display: flex;
    align-items: center;
  }
  &__posts {
    &-container {
      margin-top: 1rem;
      display: flex;
      // justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    &-loading {
      font-weight: 500;
      font-size: 1.2rem;
      padding: 0 !important;
      text-align: center;
      // position: absolute;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      > div > svg {
        transform: scale(2);
      }
    }

    &-loading2 {
      font-weight: 500;
      font-size: 1rem;
      padding: 0 !important;
      text-align: center;
      // position: absolute;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
    }
    &-post {
      &-container {
        margin: 0.5rem;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        width: 30rem;
        background-color: #fafafa;
        padding: 1rem;
        border-radius: 5px;
      }
      &-postedAt {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        margin-bottom: 0.2rem;
        margin-top: -0.5rem;
        margin-right: -0.5rem;
      }
      &-posted_by {
        display: flex;
        align-items: center;
        width: 100%;
        &-image {
          width: 2.5rem;
          height: 2.5rem;
          box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 50%;
          margin-right: 0.75rem;
        }
        &-name {
          font-size: 1.1rem;
          font-weight: 500;
          text-transform: capitalize;
          cursor: pointer;
        }
      }
      &-content {
        margin-top: 1rem;
        padding: 1rem 0;
        border-top: 1px solid #555;
      }
      &-title {
        color: #297bca;
        font-weight: 600;
        font-size: 1.1rem;
      }
      &-jobType {
        margin-right: 0.5rem;
      }
      &-location {
        margin-left: 0.5rem;
      }
      &-label {
        font-weight: 500;
        margin-bottom: 0.25rem;
        font-size: 1.05rem;
      }
      &-data {
        margin-top: 0.75rem;
      }
      &-desc {
        margin-bottom: 0.5rem;
        // margin-top: 1rem;
      }
      &-skills {
        display: flex;
        flex-wrap: wrap;
        &-section {
          margin-bottom: 0.5rem;
        }
      }
      &-skill {
        padding: 0.25rem 0.5rem;
        background-color: #297bca;
        color: #fff;
        margin: 0.2rem;
        font-size: 0.9rem;
        border-radius: 4px;
      }
      &-compensation-section {
        margin-bottom: 0.5rem;
      }
      &-exp-section {
        margin-bottom: 0.5rem;
      }
      &-applications-section {
        margin-top: 0.5rem;
      }
      &-btns {
        display: flex;
        border-top: 1px solid #444;
        padding-top: 1rem;
        justify-content: space-around;
      }
      &-viewCandidates {
        background-color: #297bca !important;
      }
      &-rejectBtn {
        // margin-right: 5rem;
        font-size: 1rem;
        background-color: #ff4e4e;
        color: #fff;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
      &-approveBtn {
        font-size: 1rem;
        background-color: #297bca;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        cursor: pointer;
      }
    }
  }
  &__pagination {
    position: sticky;
    bottom: 0px;
    background-color: #f4f6f8;
    padding: 1rem 0rem;
    display: flex;
    // font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    flex-direction: row !important;
    z-index: 999;
    &-count {
      margin-top: 0rem !important;
      color: #666;
    }
  }
  &__modal2 {
    background: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 1.1rem;
  }
  &__modal {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: 99;
      height: 100vh;
      background-color: rgba(59, 59, 59, 0.616);
    }
    &-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 38rem !important;
      transform: translate(-50%, -50%);
      &:focus {
        outline: none;
      }
    }
    &-overlay2 {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: 9999;
      height: 100vh;
      background-color: rgba(59, 59, 59, 0.616);
    }
    &-modal2 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 55rem;
      transform: translate(-50%, -50%);

      &:focus {
        outline: none;
      }
    }
    background: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    font-weight: 500;
    font-family: 'Poppins';
    font-size: 1.1rem;
    &-btns {
      margin-top: 0.75rem;
    }
    &-heading {
      font-weight: 600;
      font-family: 'Poppins';
      font-size: 1.1rem;
      padding-bottom: 0.5rem;
    }
    &-btn {
      &-reject {
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #ff4e4e;
        background-color: #ff4e4e;
        color: #fff;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
      &-cancel {
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #333;
        background-color: #fff;
        color: #000;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }

      &-confirm {
        margin-top: 10px;
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #333;
        background-color: #297bca;
        color: #ffff;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
    }
  }
  &__toast {
    position: fixed;
    bottom: 6rem;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: Poppins;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 18px;
    padding: 7px 13px;
    background-color: #e8e8e9;
  }
}
.candidate_list_items {
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 2rem;
}
.candidate_list_title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.candidate_application_count_btn{
  font-size: 16px;
  color: #fffcfc;
  border: none;
  height: 40px;
  background-color: #0077b5;
  border-radius: 10px;
  width: 20%;

}
.candidate_list_item {
  display: flex;
  align-items: center;
  // padding: 1rem;
  // margin-bottom: 1rem;
  transition: background-color 0.2s;

  &:hover {
    background-color: #f9f9f9;
  }

  &_no {
    font-size: 1.1rem;
    font-weight: 500;
    color: #666;
  }

  &_img {
    flex-shrink: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 2px solid #ddd;
    margin-right: 1rem;
    object-fit: cover;
  }

  &_name {
    font-weight: 500;
    color: #333;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    flex-grow: 1;
  }

  &_status {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    &.status_blue {
      background-color: #e7f0fe;

      color: #297bca;
    }
    &.status_red {
      background-color: #fde8e9;

      color: #ff3a30;
    }
  }

  &_applyStatus {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem 0.75rem;
    border-radius: 12px;
    font-size: 0.9rem;
    font-weight: 500;
    text-align: center;
    min-width: 7rem;
    background-color: #d5d9d6; 

    &.whatsapp {
      color: #25d366; 
      background-color: #e1f4e3; 
    }
    &.feed {
      background-color: #ffe4bc; 
      color: #d22b01; 
    }
    &.linkedin {
      background-color: #0077b5; 
      color: #fff; 
    }
    &.search {
      background-color: #dfc8e8; 
      color: #610092; 
    }
}

  &_viewProfile {
    text-decoration: none;
    color: #297bca;
    font-weight: 500;
    transition: color 0.2s;

    &:hover {
      text-decoration: underline;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.status_blue {
  color: #297bca;
}
.status_red {
  color: #ff3a30;
}
.customSelect {
  &__indicator-separator {
    display: none;
  }
}
.customSelect-container {
  color: #000;
}
.active_jobs_posts-post-posted_by-name-premium {
  margin-left: 0.5rem;
  color: rgb(0, 102, 255);
  &::before {
    content: '( ';
  }
  &::after {
    content: ' Plan User )';
  }
}
.candidate_list_item_name_r {
  background-color: #297bca;
  color: #fff;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  border-radius: 50%;
}


.toggler {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.view-toggle {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #fff;
  max-width: 200px;
  margin-right: 20px; 
  padding: 10px;
  border-radius: 14px;

  svg {
    font-size: 24px; // Size of the icons
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #297bca; // Highlight color on hover
    }
  }
}


.active_jobs__modal {
  font-family: 'Poppins', sans-serif; 

  &-modal {
    background: #fff;
    border-radius: 10px;
    padding: 2rem;
    width: 500px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 1rem;

    input, button, div { 
      font-family: 'Poppins', sans-serif;
    }

    input {
      padding: 0.8rem;
      border: 1px solid #ccc;
      border-radius: 4px;
      font-size: 1rem;
      width: calc(100% - 1.6rem);

      &:focus {
        border-color: #297bca;
        outline: none;
      }
    }

    button {
      padding: 0.8rem;
      background-color: #297bca;
      color: white;
      border: none;
      border-radius: 4px;
      cursor: pointer;
      font-weight: 500;

      &:hover {
        background-color: darken(#297bca, 10%);
      }
    }

    .campaign-error {
      color: #ff4e4e;
      font-size: 0.9rem;
      padding-top: 0.5rem;
    }
  }
}

.active_jobs__link-box {
  padding: 1rem;
  margin-top: 1rem;
  background-color: #f0f4f7;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: #e1e5e9;
  }

  .active_jobs__link-text {
    font-size: 1rem;
    color: #297bca;
  }

  .active_jobs__link-icon {
    font-size: 1.5rem;
    color: #297bca;
  }
}

.active_jobs__filter{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 130vw;
}
.active_jobs__filter_radio{
  display: flex;
  align-items: center;
  gap:30px;
  font-size: medium;
}