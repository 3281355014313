.title {
    //styleName: Medium/Text/TMedium1;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    padding-bottom: 4px;
    color: #4D5359CC;
    // margin-bottom: 8px;
  }
  
  .subTitle {
    //styleName: Regular/Text/TRegular3;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0rem;
    text-align: left;
    color: #9DA3A8;
    // height: 1px;
  }
  
  .requiredAsterisk {
    color: #F53348; // Red color for the asterisk
    margin-right: 4px; // Space between the asterisk and the title text
  
    //styleName: Medium/Text/TMedium1;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
  }
  
  .textField {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    width: 100%;
    border: 1px solid #d1d4d7;
    border-radius: 6px;
    background-color: #ffffff;
    height: 56px;
    color: #4D5359;
    padding: 16px 24px;
  }
  
  .textField::placeholder {
    color: #9DA3A8;
    font-size: 12px;
  }
  
  .textField:focus {
    outline: none;
    border: 1px solid #297bca;
  }
  
  .errorField {
    border-color: #FF2038B2;
  }
  
  .errorMessage {
    color: #FF2038B2;
    font-family: Poppins;
    height: 0px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    margin: 0;
    padding-left: 2px;
    min-height: 10px;
  }
  
  .row {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    width: 100%;
    gap: 4px;
  
    // span{
    //   display: none;
    // }
  
    //to remove the dash before the dropdown icon
    :last-child span {
      display: none;
    }
  }
  
  .column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  
  .suggCards {
    display: flex;
    flex-wrap: wrap;
  }
  
  .suggCard {
    border-radius: 16px;
  
    font-family: Poppins;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    align-items: center;
  
    margin-top: 0.5rem;
    margin-right: 0.75rem;
    padding: 4px 10px;
    color: #297BCA;
    background: #EAF2FA;
    cursor: pointer;
  
  }
  
  .suggformCardActive {
    border-radius: 20px;
    background: #EAF2FA;
  }
  
  .getJdFromJobDatacontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    // margin: 0.75rem 0;
    width: 100%;
  
    .button {
      cursor: pointer;
      background: #297BCA;
      border-radius: 4px;
      font-family: Poppins;
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
      color: #fff;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 10rem;
      padding: 4px 32px;
  
      background: linear-gradient(90deg,
          rgba(41, 123, 202, 1) 0%,
          rgb(89, 166, 237) 30%,
          rgba(41, 123, 202, 1) 67%,
          rgba(41, 123, 202, 1) 100%);
  
  
      /* Ensure the background is large enough to allow a visible shift */
      background-size: 800% 100%;
      transition: background-position 0.5s, background-size 0.5s;
      /* Smooth transition for background changes */
  
      @media screen and (max-width: 768px) {
        padding: 0;
        height: 2.4rem;
        width: 100%;
        font-size: 16px;
      }
  
      @media screen and (max-width: 500px) {
        padding: 0;
        height: 2.4rem;
        width: 100%;
        font-size: 11px;
      }
  
      img {
        height: 80%;
        padding: 5px;
      }
    }
  
    .gradientAnimated {
      animation: shiftGradient 2s linear infinite;
    }
  
    @keyframes shiftGradient {
      0% {
        background-position: 0% 38%;
      }
  
      50% {
        background-position: 100% 63%;
      }
  
      100% {
        background-position: 0% 38%;
      }
    }
  }
  
  
  .titleContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    row-gap: 4px;
  
    @media screen and (max-width: 500px) {
      flex-direction: column;
  
      div {
        width: 100%;
      }
    }
  }