.offer_card {
  background-color: #fff;
  padding: 1.4rem;
  border-radius: 9px;
  font-family: Roboto;
  &-title {
    font-size: 1.4rem;
    font-weight: 500;
    // text-align: center;
    margin-bottom: 1rem;
    color: #40444f;
  }
  &-grp {
    margin-bottom: 0.75rem;
    &-label {
      font-size: 1.25rem;
      font-weight: 500;
      color: #40444f;
      margin-bottom: 0.1rem;
    }
    &-value {
      font-size: 1.2rem;
      text-transform: capitalize;
      color: #40444f;
      display: flex;
      flex-wrap: wrap;
      &-skill {
        border-radius: 15px;
        margin-right: 0.3rem;
        margin-bottom: 0.3rem;
        padding: 0.2rem 0.8rem;
        background-color: rgb(176, 217, 255);
      }
    }
  }
}
