.editJob {
  &__container {
    font-family: Roboto;
    background-color: #fff;
    padding: 1.5rem;
    border-radius: 9px;
  }
  &__title {
    font-size: 1.4rem;
    margin-bottom: 1.5rem;
    font-weight: 500;
  }

  &__label {
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
  }
  &__select {
    margin-bottom: 1rem;
  }
  &__selectedItems {
    display: flex;
    flex-wrap: wrap;
    &-item {
      padding: 0.25rem 0.5rem;
      display: flex;
      border-radius: 14px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      background-color: #1766f8;
      color: #fff;
      margin-right: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &-exp {
      border-radius: 4px;
      box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.2);
      margin-bottom: 0.75rem;
      margin-right: 0.75rem;
      background-color: #fff;
      width: 6rem;
      height: 2.5rem;
      align-items: center;
      display: flex;
      justify-content: center;
      cursor: pointer;
      &-active {
        background-color: #1766f8;
        color: #fff;
      }
    }
  }
  &__text {
    width: 100%;
    padding: 0.65rem 0.75rem;
    background-color: #eeeeee;
    border: none;
    border-radius: 9px;
    font-size: 15px;
    margin-bottom: 0.5rem;
  }
  &__checkbox {
    margin-bottom: 1rem;
  }
  &__textarea {
    width: 100%;
    font-family: Roboto;
    height: 8rem;
    resize: none;
    padding: 0.5rem;
    font-size: 1rem;
  }
  &__btns {
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    &-cancel {
      margin-right: 4rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 7rem;
      height: 2.3rem;
      border: 1px solid #222;
      border-radius: 4px;
      cursor: pointer;
    }
    &-next {
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      width: 7rem;
      height: 2.3rem;
      color: #fff;
      background-color: #1766f8;
      border: 1px solid #1766f8;
      border-radius: 24px;
    }
  }
}
.customSelect {
  &__indicator-separator {
    display: none;
  }
}
.customSelect-container {
  color: #000;
}
