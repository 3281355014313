.container {
  margin-top: 0.5rem;
  font-size: small;
  font-family: 'Poppins', sans-serif;
  padding: 1.5rem;
  // background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.drawerContent {
  width: 600px;
  padding: 20px;

  height: 100%;
  padding-block: 3rem;
}

.mainCont {
  padding-block: 30px;
  overflow: scroll;
}

.step1Div {
  width: 100%;

  overflow: scroll;

  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: center;

  margin-block: auto;

}

.header {
  text-align: center;

  color: var(--IOS-text-color-Secondary-Black, rgba(60, 60, 67, 0.70));
  text-align: center;
  font-family: Satoshi;
  font-size: large;

  & > h1 {
      color: #2369AC;
      font-weight: 700;
      font-family: Satoshi;
  }

  @media (max-width: 800px) {
      width: 80%;
  }

  @media (max-width: 500px) {
      width: 90%;
  }
}

.IntroInfoGrp {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 80%;
  gap: 20px;

  & > .IntroInfo {
      padding: 20px;

      border-radius: 8px;
      border: 1px solid #E5E7EB;
      background: #FFF;

      & > h4 {
          color: #2369AC;
          font-family: Poppins;
          font-size: medium;
          font-style: normal;
          font-weight: 700;
          display: flex;
          align-items: center;
          gap: 10px;
      }

      & > div {
          color: #77777C;
          font-family: Poppins;
          font-style: normal;
          font-weight: 400;
      }

  }

  @media (max-width: 800px) {
      grid-template-columns: 1fr;
  }

  @media (max-width: 500px) {
      width: 90%;
  }

}

.buttonGrp {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 80%;

  & > button {
      flex-grow: 1;
      padding: 16px;
      border-radius: 145px;
      border: 1px solid #2369AC;
      font-weight: 500;
      font-family: Poppins;
  
      background-color: #2369AC;
      color: #FFF;
  }
}


.assessmentContainer {
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;

  .title {
    font-size: 1.8rem;
    font-weight: 600;
    color: #297bca;
    margin-bottom: 1.5rem;
    text-align: center;
  }

  .assessmentTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;

    th,
    td {
      padding: 1rem;
      text-align: center;
      border: 1px solid #ddd;
    }

    th {
      background-color: #f0f4f8;
      color: #555;
      font-weight: 600;
    }

    td {
      color: #555;
    }

    .userLink {
      color: #297bca;
      cursor: pointer;
      text-decoration: underline;
      &:hover {
        color: #1e5a8a;
      }
    }

    .viewReportButton {
      border: 1px solid #297bca;
      background-color: #ffffff;
      color: #297bca;
      border-radius: 20px;
      padding: 0.5rem 1rem;

      &:hover {
        background-color: #297bca;
        color: #ffffff;
      }
    }
  }

  .pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .noData {
    text-align: center;
    font-size: 1.2rem;
    color: #999;
    margin-top: 2rem;
  }
}

// .closeBtn {
//   position: absolute;
//   left: 15px;
//   top: 20px;
// }

.mainContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.contentContainer {
  padding-block: 2rem;
  display: flex;
  flex-direction: column;
  gap: 10px;

  height: 100%;
}

.fieldContainer {
  display: flex;
  flex-direction: column;
  gap: 1px;
}