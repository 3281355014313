.container {
    margin-top: 0.5rem;
    font-size: small;
    font-family: 'Poppins', sans-serif;
    padding: 1.5rem;
    // background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: auto;
}
  
.drawerContent {
    width: 50vw;
    height: 100%;
    padding: 0 !important;
}

.mainContainer {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 100%;

    & > div:first-child {
        border-right: .5px solid #afafaf;
        overflow-y: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    & > div {
        width: 100%;
        height: 100%;
        padding: 20px;
        overflow: hidden;
    }

    & > .rightChild {
        display: flex;
        flex-direction: column;

        gap: 30px;

        & > .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        & > .infoDiv {
            display: flex;
            flex-direction: column;
            gap: 20px;

            & > div {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: .875rem;
                line-height: 1.25rem;
                
                & > div:first-child {
                    font-weight: 600;
                }
            }
        }
    }
}

.conversationDiv {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    & > .Convo {
        padding: 14px 20px;
        max-width: 80%;
        width: fit-content;
        font-size: .875rem;
        line-height: 1.25rem;
        letter-spacing: 0;
        color: #000;
        border-color: rgba(125, 135, 155, .2);
        border-radius: 1rem;
        display: flex;
        justify-content: space-between;
        gap: 5px;

        position: relative;

        & > div {
            margin-top: auto;
            color: rgba(8, 20, 43, .6);
        }
    }

    & > .AgentTranscript {
        margin-right: auto;
        background-color: rgba(135, 155, 175, .05);
    }
    
    & > .UserTranscript {
        margin-left: auto;
        background-color: rgba(135, 145, 165, .1);
    }
}

.OverviewContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > .infoContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &.callStatus {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            & > div {
                width: fit-content;
            }
        }
    
        & > .informationDiv {
            font-size: .875rem;
            line-height: 1.25rem;
            letter-spacing: 0;
        }
    }
}

.summaryInfo {
    display: flex;
    flex-direction: column;
    gap: 10px;

    & > div {
        padding: .5rem;
        font-size: .875rem;
        line-height: 1.25rem;
        letter-spacing: 0;

        display: flex;
        flex-direction: column;
        gap: 6px;

        & > h6 {
            font-weight: 500;
        }

        & > div {
            font-weight: 400;
        }   
    }
}

.msInfo {
    position: absolute;
    bottom: 0%;
    transform: translateY(50%);
}

.headersDataCollection {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.specialTextClass {
    --text-color: '41, 112, 188'
}

.AudioPlayer {
    width: 100%;
    padding: 30px 0;
}