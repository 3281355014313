.jobs_under_review {
  &__container {
    padding: 2rem;
    font-family: 'Roboto';
  }
  &__header {
    font-size: 1.7rem;
    font-weight: 500;
  }
  &__row {
    display: flex;
    align-items: center;
  }
  &__posts {
    &-container {
      margin-top: 1rem;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    &-loading {
      font-weight: 500;
      font-size: 1.2rem;
      padding: 0 !important;
      text-align: center;
      // position: absolute;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      > div > svg {
        transform: scale(2);
      }
    }
    &-post {
      &-jobType2 {
        display: inline-block;
        border: 1px solid #297bca;
        color: #297bca;
        font-weight: 500;
        padding: 0.15rem 0.7f5rem;
        border-radius: 15px;
        margin-bottom: 0.75rem;
      }
      &-container {
        font-family: 'Roboto';
        margin: 0.5rem;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        width: 30rem;
        background-color: #fafafa;
        padding: 1rem;
        border-radius: 5px;
      }
      &-business {
        margin-bottom: 0.5rem;
      }
      &-postedAt {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
      }
      &-posted_by {
        display: flex;
        align-items: center;
        &-image {
          width: 2.5rem;
          height: 2.5rem;
          box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 50%;
          margin-right: 0.75rem;
        }
        &-name {
          font-size: 1.1rem;
          font-weight: 500;
          text-transform: capitalize;
          cursor: pointer;
        }
      }
      &-content {
        margin-top: 1rem;
        padding: 1rem 0;
        border-top: 1px solid #555;
      }
      &-title {
        color: #297bca;
        font-weight: 500;
        text-transform: capitalize;
        font-size: 1.1rem;
      }
      &-jobType {
        margin-right: 0.5rem;
      }
      &-location {
        margin-left: 0.5rem;
      }
      &-label {
        font-weight: 500;
        margin-bottom: 0.25rem;
        font-size: 1.05rem;
      }
      &-data {
        margin-top: 0.75rem;
      }
      &-desc {
        margin-bottom: 0.5rem;
        // margin-top: 1rem;
      }
      &-skills {
        display: flex;
        flex-wrap: wrap;
        &-section {
          margin-bottom: 0.5rem;
        }
      }
      &-skill {
        padding: 0.25rem 0.5rem;
        background-color: #297bca;
        color: #fff;
        margin: 0.2rem;
        font-size: 0.9rem;
        border-radius: 4px;
      }
      &-compensation-section {
        margin-bottom: 0.5rem;
      }
      &-exp-section {
        margin-bottom: 0.5rem;
      }
      &-btns {
        display: flex;
        border-top: 1px solid #444;
        padding-top: 1rem;
        justify-content: space-around;
      }
      &-rejectBtn {
        // margin-right: 5rem;
        font-size: 1rem;
        background-color: #ff4e4e;
        color: #fff;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
      &-approveBtn {
        font-size: 1rem;
        background-color: #297bca;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        cursor: pointer;
      }
    }
  }
  &__pagination {
    position: sticky;
    bottom: 0px;
    background-color: #f4f6f8;
    padding: 1rem 0rem;
    display: flex;
    // font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &-count {
      margin-top: 0.5rem;
      color: #666;
    }
  }
  &__modal {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: 2;
      height: 100vh;
      background-color: rgba(59, 59, 59, 0.616);
    }
    &-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30rem;
      transform: translate(-50%, -50%);
      &:focus {
        outline: none;
      }
    }
    background: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 1.1rem;
    &-btns {
      margin-top: 0.75rem;
    }
    &-btn {
      &-reject {
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #ff4e4e;
        background-color: #ff4e4e;
        color: #fff;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
      &-cancel {
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #333;
        background-color: #fff;
        color: #000;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
    }
  }
  &__toast {
    position: fixed;
    z-index: 100000;
    bottom: 6rem;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: Roboto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 18px;
    padding: 7px 13px;
    background-color: #e8e8e9;
  }
}

.active_jobs_posts-post-posted_by-name-count {
  border: 3px solid #297bca;
  border-radius: 50%;
  min-width: 2.25rem;
  width: 2.25rem;
  height: 2.25rem;
  color: #074fea;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
