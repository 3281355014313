.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;
}
.container svg {
  width: 30%;
  max-width: 6rem;
}
@media screen and (max-width: 500px) {
  .container svg {
    max-width: 5rem;
  }
}
