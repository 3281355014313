.MainContainer {
    padding: 20px;

    display: flex;
    flex-direction: column;
    gap: 20px;

    & > .header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &  > .createButton {
            display: flex;
            align-items: center;
            gap: 10px;
        }
    }
}

.addRouteModal {
    background-color: white;
    width: 40rem;
    min-height: 30vh;
    margin: auto;

    border-radius: 12px;
    padding: 30px;

    & > h2 {
        margin: 0;
    }

    & > .addRouteForm {
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }
}