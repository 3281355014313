.container {
  padding: 2rem;
  border-radius: 15px;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  width: 100%;
  margin: 0;
  box-shadow: none;
}

.title {
  font-size: 2rem;
  font-weight: 700;
  color: #297bca;
  text-align: center;
  margin-bottom: 2rem;
  cursor: pointer;
}

.headerSection {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.pfp {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  margin-right: 2rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.userInfo {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.detail {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}

.detail strong {
  width: 200px;
  color: #40444f;
}

.value {
  font-size: 1rem;
  color: #555;
}

.tagContainer {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
}

.tag {
  background-color: #297bca;
  color: #fff;
  border-radius: 20px;
  padding: 0.5rem 1.5rem;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 0.85rem;
}

.skillCards {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 2rem;
}

.skillCards strong {
  margin-right: 0.5rem;
  color: #40444f;
}

.skillCard {
  cursor: default;
  color: #fff;
  background-color: #297bca;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  margin: 0 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1.5rem;
}
.detail strong {
  width: 200px;
  color: #40444f;
}

.required {
  color: #ff4e4e;
  margin-left: 0.25rem;
  font-size: 1rem;
  background-color: #ffe6e6;
  padding: 0.2rem 0.5rem;
  border-radius: 8px;
  font-weight: bold;
}

.question {
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
}

.analyticsContainer {
  margin-top: 2rem;
  padding: 2rem;
  border: 1px solid #ddd;
  border-radius: 15px;
  background-color: #f0f4f8;
}

.analyticsTitle {
  font-size: 1.8rem;
  font-weight: 600;
  color: #297bca;
  margin-bottom: 1.5rem;
  text-align: center;
}

.analyticsMetrics {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: center;
}

.metricItem {
  flex: 1 1 200px;
  background-color: #fff;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.metricLabel {
  display: block;
  font-size: 1rem;
  color: #555;
  margin-bottom: 0.5rem;
}

.metricValue {
  font-size: 1.8rem;
  font-weight: bold;
  color: #297bca;
}

.statusGroup {
  margin-top: 2rem;
}

.statusGroup h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #297bca;
  margin-bottom: 1rem;
  text-align: center;
}

.statusList {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: center;
}

.statusItem {
  display: flex;
  align-items: center;
  background-color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.statusLabel {
  font-size: 1rem;
  color: #555;
  margin-right: 0.5rem;
}

.statusCount {
  font-size: 1.3rem;
  font-weight: bold;
  color: #297bca;
}

.remarks__container {
  margin-top: 2rem;
  max-height: 300px;
  overflow-y: auto;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.remarks-header {
  font-weight: bold;
  font-size: 1.3em;
  margin-bottom: 10px;
  text-align: center;
}

.remark__item {
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.remark__admin-name {
  font-weight: bold;
}

.admin-link {
  color: #297bca;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.remark__text {
  margin-top: 5px;
  font-weight: 300;
}

.remark__timestamp {
  float: right;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
}

.remark-input-container {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
}

.remark-input-box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  padding: 10px;
  margin-top: 5px;
}

.remark-input {
  flex-grow: 1;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 1em;
  outline: none;
  resize: none;
}

.send-button {
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 10px;
}

.send-button:hover {
  opacity: 0.8;
}

.remark-input:focus {
  border: 1px solid #297bca;
}

.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #297bca;
  color: #fff;
  padding: 15px 20px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  z-index: 1000;
  font-family: Poppins, sans-serif;
  animation: fadeInOut 3s ease-in-out;
}

.toast.error {
  background-color: #ff4e4e;
}

.toast.success {
  background-color: #297bca;
}

.no-follow-up-checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.no-follow-up-checkbox input {
  margin-right: 5px;
}

.large-label {
  font-size: 1.5rem;
}

.next-follow-up {
  margin-top: 10px;
  font-weight: bold;
  color: #ff4e4e;
}

.next-follow-up-date {
  display: block;
  margin-top: 5px;
  color: #297bca;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.NPSButtonDiv {
  display: flex;
  gap: 10px;

  align-self: start;
  align-items: center;
}