.mainContainer {
    display: flex;
    gap: 20px;

    & > .leftContainer {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;

        & > .aiInterviewDashboardMainDiv {
            width: 100%;
            height: 100%;

            padding: 30px 100px;

            display: flex;
            flex-direction: column;

            gap: 20px;

            & > .mainContentDivHeader {
                display: flex;
                justify-content: space-between;

                & > .TopHeader {
                    display: flex;
                    gap: 30px;

                    & > h3 {
                        margin: 0;
                        color: #020817;
                        font-family: 'Poppins', sans-serif;
                        font-size: 24px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px; /* 155.556% */

                        display: flex;
                        align-items: center;
                    }

                    & > .TabSwitch {
                        display: flex;
                        align-items: center;
                        gap: 20px;
                        cursor: pointer;

                        & > * {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: 100%;
                            padding-inline: 10px;
                            position: relative;
                            width: 100px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            color: #6C757D;

                            &.active {
                                color: #2369AC;

                                &::before {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    height: 3px;
                                    border-radius: 100px;
                                    overflow: hidden;

                                    background-color: #2369AC;
                                    left: 0;

                                    width: 100%;
                                }
                            }

                            &:hover {
                                color: #2369AC;
                                transition: 0.5s color;

                                &::after {
                                    content: '';
                                    position: absolute;
                                    bottom: 0;
                                    height: 3px;
                                    border-radius: 100px;
                                    overflow: hidden;

                                    background-color: #2369AC;
                                    left: 0;

                                    width: 0;

                                    animation: incWidth forwards 1s ease;

                                    @keyframes incWidth {
                                        to {
                                            width: 100%;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    @media (max-width: 740px) {
                        & > h3 {
                            font-size: 16px;
                        }

                        & > .TabSwitch {
                            gap: 10px;

                            & > * {
                                font-size: 12px;
                            }
                        }
                    }

                    @media (max-width: 800px) {
                        width: 100%;
                        justify-content: space-between;
                        & > .TabSwitch {
                            & > * {
                                width: auto;
                            }
                        }
                    }
                }

                & > .CreateInterview {
                    display: flex;
                    gap: 10px;

                    text-decoration: none;

                    color: #FAFAFA;
                    font-family: 'Poppins', sans-serif;
                    font-size: 16px;
                    border-radius: 145px;
                    border: 1px solid #2369AC;
                    background: #2369AC;

                    padding: 15px;

                    @media (max-width: 1100px) {
                        & > div {
                            display: none;
                        }
                    }

                    @media (max-width: 500px) {
                        display: none;
                    }
                }
            }

            & > .cardDiv {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 30px;

                & > .card {
                    border-radius: 10px;
                    border: 1px solid #F0F1F2;
                    background: #FFF;

                    /* message box */
                    box-shadow: 6px 4px 10.7px 0px rgba(204, 204, 204, 0.25);
                    padding: 30px;

                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    & > .title {
                        color: #020817;
                        font-family: 'Poppins', sans-serif;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 28px; /* 155.556% */
                    }

                    & > .createdOn {
                        color: #717885;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 16px; /* 133.333% */
                    }

                    & > .hirerInfo {
                        color: #717885;
                        font-family: 'Poppins', sans-serif;
                        font-size: 14px;
                        margin-bottom: 10px;
                        cursor: pointer;

                        & > .hirerName {
                            color: #2369AC;
                            font-weight: 700;
                            text-decoration: underline;
                        }
                    }

                    & > .metrics {
                        display: flex;
                        flex-wrap: wrap;
                        gap: 15px;
                        margin-top: 10px;

                        & > .metric {
                            display: flex;
                            flex-direction: column;

                            & > .metricLabel {
                                color: #6C757D;
                                font-family: 'Poppins', sans-serif;
                                font-size: 12px;
                                font-weight: 400;
                            }

                            & > .metricValue {
                                color: #020817;
                                font-family: 'Poppins', sans-serif;
                                font-size: 16px;
                                font-weight: 600;
                            }
                        }
                    }

                    & > .skills {
                        margin-top: 10px;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;
                    }

                    & > .addons {
                        margin-top: 10px;
                        display: flex;
                        flex-wrap: wrap;
                        gap: 5px;
                    }

                    & > .ButtonGrp {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;


                        margin-top: auto;

                        & > .copyButton {
                            display: flex;
                            align-items: center;
                            gap: 5px;
                            border-radius: 20px;
                            background-color: #FFF;
                            color: #2369AC;
                            border: none;
                            padding: 10px 15px;

                            cursor: pointer;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            border: 1px solid #2369AC;

                            &:hover {
                                background-color: #1e5a8a;
                                color: #FFF;
                            }

                            & > svg {
                                width: 16px;
                                height: 16px;
                            }
                        }

                        & > .viewAssessmentsButton {
                            text-decoration: none;
                            border-radius: 20px;
                            background-color: #297bca;
                            color: #FFF;
                            padding: 10px 15px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 14px;
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            gap: 5px;

                            &:hover {
                                background-color: #1e5a8a;
                            }
                        }
                    }
                }

                @media (max-width: 1000px) {
                    grid-template-columns: 1fr;
                }
            }

            & > .paginationContainer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 20px;
                gap: 10px;

                & > .paginationCount {
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    color: #6C757D;
                }

                & .MuiPagination-root {
                    .Mui-selected {
                        background-color: #2369AC;
                        color: #FFF;
                    }
                }

                & > .goToPageInput {
                    width: 100px;
                }

                & > .goToPageButton {
                    background-color: #2369AC;
                    color: #FFF;
                    border: none;
                    padding: 8px 12px;
                    border-radius: 5px;
                    cursor: pointer;

                    &:hover {
                        background-color: #1e5a8a;
                    }
                }
            }

            & > .FallBackSection {
                display: flex;
                width: 100%;
                height: 100%;

                flex-direction: column;
                gap: 10px;
                align-items: center;
                justify-content: center;

                & > h3 {
                    color: #020817;
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;

                    margin: 0;
                }

                & > p {
                    color: #899197;
                    text-align: center;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;

                    margin: 0;
                }

                & > .CreateInterview {
                    display: flex;
                    gap: 10px;

                    text-decoration: none;

                    color: #FAFAFA;
                    font-family: 'Poppins', sans-serif;
                    font-size: 14px;
                    border-radius: 145px;
                    border: 1px solid #2369AC;
                    background: #2369AC;

                    padding: 15px;

                    & > svg {
                        width: 15px;
                    }

                    @media (max-width: 1100px) {
                        & > div {
                            display: none;
                        }
                    }
                }
            }

            @media (max-width: 1000px) {
                padding-inline: 10px;
            }
        }
    }

    & *::-webkit-scrollbar {
        display: none;
    }

    .skill {
        display: inline-block;
        padding: 5px 10px;
        margin: 5px 5px 0 0;
        background-color: #eaf2fa;
        color: #297bca;
        font-size: 14px;
        border-radius: 20px;
        font-family: 'Poppins', sans-serif;
    }

    .addon {
        display: inline-block;
        padding: 5px 10px;
        margin: 5px 5px 0 0;
        background-color: #f0f1f2;
        color: #2369AC;
        font-size: 14px;
        border-radius: 20px;
        font-family: 'Poppins', sans-serif;
    }

    .paginationContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        gap: 10px;
    }

    .paginationCount {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: #6C757D;
    }

    .goToPageInput {
        width: 100px;
    }

    .goToPageButton {
        background-color: #2369AC;
        color: #FFF;
        border: none;
        padding: 8px 12px;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #1e5a8a;
        }
    }

    .hirerInfo {
        color: #717885;
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .hirerName {
        color: #2369AC;
        font-weight: 700;
        text-decoration: underline;
    }

    .metrics {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        margin-top: 10px;
    }

    .metric {
        display: flex;
        flex-direction: column;
    }

    .metricLabel {
        color: #6C757D;
        font-family: 'Poppins', sans-serif;
        font-size: 12px;
        font-weight: 400;
    }

    .metricValue {
        color: #020817;
        font-family: 'Poppins', sans-serif;
        font-size: 16px;
        font-weight: 600;
    }

    .skills {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    .addons {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
        gap: 5px;
    }

    .ButtonGrp {
        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-top: auto;

        .copyButton {
            display: flex;
            align-items: center;
            gap: 5px;
            background-color: #2369AC;
            color: #FFF;
            border: none;
            padding: 10px 15px;
            border-radius: 5px;
            cursor: pointer;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;

            &:hover {
                background-color: #1e5a8a;
            }

            & > svg {
                width: 16px;
                height: 16px;
            }
        }

        .viewAssessmentsButton {
            text-decoration: none;
            background-color: #297bca;
            color: #FFF;
            padding: 10px 15px;
            border-radius: 5px;
            font-family: 'Poppins', sans-serif;
            font-size: 14px;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 5px;

            &:hover {
                background-color: #1e5a8a;
            }
        }
    }

    @media (max-width: 1000px) {
        .cardDiv {
            grid-template-columns: 1fr;
        }
    }
}
