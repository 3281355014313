.container {
  padding: 1.5rem;
  font-family: Roboto;
}
.title {
  font-size: 1.4rem;
  font-weight: 500;
}
.chatBox {
  width: 40rem;
  padding: 1rem;
  height: 75vh;
  margin-top: 2rem;
  display: flex;
  border-radius: 9px;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.06);
  background-color: #fff;
  overflow-y: auto;
  flex-direction: column-reverse;
}
.userPfp {
  width: 30px;
  height: 30px;
  overflow: hidden;
  border-radius: 50%;
  img {
    width: 100%;
  }
}
.messageContainer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 1.2rem;
}
.messageBubble {
  border-radius: 11px;
  max-width: 70%;
  padding: 0.75rem;
}
.messageContainerSent {
  flex-direction: row;
  .userPfp {
    margin-right: 1rem;
  }
  .messageBubble {
    background-color: #f2f9ff;
    border-bottom-left-radius: 0;
    color: #333;
  }
}
.messageContainerReceived {
  flex-direction: row-reverse;
  .userPfp {
    margin-left: 1rem;
  }
  .messageBubble {
    background-color: #389fff;
    border-bottom-right-radius: 0;
    color: #fff;
    margin-right: 0.5rem;
  }
}

.chatDateContainer {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  justify-content: center;
  position: relative;
  margin: 1rem 0;
}
.chatDateLine {
  width: 100%;
  border-top: 0.5px solid #777;
  height: 1px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
}
.chatDateValue {
  position: relative;
  font-size: 13px;
  // font-weight: 500;
  z-index: 1;
  color: #777;
  padding: 0 0.5rem;
  background-color: #fff;
}
.messageTime {
  margin-top: 0.5rem;
  font-size: 13px;
}

.loaderContainer {
  width: 100%;
  text-align: center;
  margin: 0.5rem 0;
}
