.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  font-family: 'Poppins';
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  width: 400px;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  background-color: rgba(59, 59, 59, 0.616);
}

.container {
  text-align: center;
}

.alertIcon {
  margin-bottom: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.message {
  font-size: 16px;
  margin-bottom: 20px;
}

.dismissButton, .linkButton {
  background-color: #297bca;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  margin: 5px;
}

.dismissButton:hover, .linkButton:hover {
  background-color: #1d5b99;
}

.info {
  background-color: #E8F5E9;
}

.alert {
  background-color: #FBE9E7;
}

.loadingBar {
  height: 5px;
  width: 100%; 
  transition: width 1s linear; 
}

.info .loadingBar {
  background-color: #34A853; 
}

.alert .loadingBar {
  background-color: #EA4335; 
}

