.active_jobs {
  &__container {
    padding: 2rem;
    font-family: 'Roboto';
  }
  &__search {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    width: 20rem;
    padding: 0.25rem 0.5rem;
    border: 1px solid #444;
    border-radius: 4px;
    background-color: #fff;
    & input {
      border: none;
      width: calc(100% - 1rem);
      margin-right: 0.5rem;
      font-size: 1.1rem;
      &:focus {
        outline: none;
      }
    }
  }
  &__header {
    font-size: 1.7rem;
    font-weight: 500;
  }
  &__row {
    display: flex;
    align-items: center;
  }
  &__posts {
    &-container {
      margin-top: 1rem;
      display: flex;
      // justify-content: center;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    &-loading {
      font-weight: 500;
      font-size: 1.2rem;
      padding: 0 !important;
      text-align: center;
      // position: absolute;
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;
      > div > svg {
        transform: scale(2);
      }
    }
    &-post {
      &-container {
        margin: 0.5rem;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        width: 30rem;
        background-color: #fafafa;
        padding: 1rem;
        border-radius: 5px;
      }
      &-postedAt {
        float: right;
        font-size: 0.8rem;
        font-weight: 500;
        margin-bottom: 0.2rem;
        margin-top: -0.5rem;
        margin-right: -0.5rem;
      }
      &-posted_by {
        display: flex;
        align-items: center;
        &-image {
          width: 2.5rem;
          height: 2.5rem;
          box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 50%;
          margin-right: 0.75rem;
        }
        &-name {
          font-size: 1.1rem;
          font-weight: 500;
          text-transform: capitalize;
          cursor: pointer;
        }
      }
      &-content {
        margin-top: 1rem;
        padding: 1rem 0;
        border-top: 1px solid #555;
      }
      &-title {
        color: #297bca;
        font-weight: 500;
        font-size: 1.1rem;
      }
      &-jobType {
        margin-right: 0.5rem;
      }
      &-location {
        margin-left: 0.5rem;
      }
      &-label {
        font-weight: 500;
        margin-bottom: 0.25rem;
        font-size: 1.05rem;
      }
      &-data {
        margin-top: 0.75rem;
      }
      &-desc {
        margin-bottom: 0.5rem;
        // margin-top: 1rem;
      }
      &-skills {
        display: flex;
        flex-wrap: wrap;
        &-section {
          margin-bottom: 0.5rem;
        }
      }
      &-skill {
        padding: 0.25rem 0.5rem;
        background-color: #297bca;
        color: #fff;
        margin: 0.2rem;
        font-size: 0.9rem;
        border-radius: 4px;
      }
      &-compensation-section {
        margin-bottom: 0.5rem;
      }
      &-exp-section {
        margin-bottom: 0.5rem;
      }
      &-applications-section {
        margin-top: 0.5rem;
      }
      &-btns {
        display: flex;
        border-top: 1px solid #444;
        padding-top: 1rem;
        justify-content: space-around;
      }
      &-viewCandidates {
        background-color: #297bca !important;
      }
      &-rejectBtn {
        // margin-right: 5rem;
        font-size: 1rem;
        background-color: #ff4e4e;
        color: #fff;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
      &-approveBtn {
        font-size: 1rem;
        background-color: #297bca;
        color: #fff;
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        cursor: pointer;
      }
    }
  }
  .pagination {
    position: sticky;
    bottom: 0px;
    background-color: #f4f6f8;
    padding: 1rem 0rem;
    display: flex;
    gap: 50px;
    // font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &-count {
      margin-top: 0.5rem;
      color: #666;
    }
  }
  @media (max-width: 500px) {
    .active_jobs__pagination {
      flex-direction: column;
      align-items: center;
      padding: 10px;
      gap: 10px;
    }

    .active_jobs__pagination-count {
      font-size: 0.8rem; /* smaller font size for mobile */
      text-align: center;
    }

    .MuiTextField-root,
    .MuiButton-root {
      /* Assuming you are using Material-UI */
      font-size: 0.8rem;
    }
  }
  &__modal2 {
    background: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 1.1rem;
  }
  &__modal {
    &-overlay {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: 9999;
      height: 100vh;
      background-color: rgba(59, 59, 59, 0.616);
    }
    &-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30rem;
      transform: translate(-50%, -50%);
      &:focus {
        outline: none;
      }
    }
    &-overlay2 {
      position: fixed;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      z-index: 9999;
      height: 100vh;
      background-color: rgba(59, 59, 59, 0.616);
    }
    &-modal2 {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 55rem;
      transform: translate(-50%, -50%);

      &:focus {
        outline: none;
      }
    }
    background: #fff;
    border-radius: 10px;
    padding: 1.5rem;
    font-weight: 500;
    font-family: 'Roboto';
    font-size: 1.1rem;
    &-btns {
      margin-top: 0.75rem;
    }
    &-btn {
      &-reject {
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #ff4e4e;
        background-color: #ff4e4e;
        color: #fff;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
      &-cancel {
        margin-right: 5rem;
        font-size: 1rem;
        color: #fff;
        border: 1px solid #ff4e4e;
        background-color: #ff4e4e;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
    }
  }
  &__toast {
    position: fixed;
    bottom: 6rem;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: Roboto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
    border-radius: 18px;
    padding: 7px 13px;
    background-color: #e8e8e9;
  }
}
.candidate_list_items {
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 2rem;
}
.candidate_list_title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.candidate_list_item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
  & div {
    display: flex;
    align-items: center;
  }
  &_img {
    margin-right: 0.5rem;
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
  }
  &_name {
    font-size: 1.1rem;
    width: 20rem;
    text-transform: capitalize;
  }
  &_status {
    width: 7.5rem;
    text-align: center;
  }
  &_viewProfile {
    justify-self: end;
    color: #297bca;
    cursor: pointer;
  }
}

.status_blue {
  color: #297bca;
}
.customSelect {
  &__indicator-separator {
    display: none;
  }
}
.customSelect-container {
  color: #000;
}

.contact_list_items {
  max-height: 80vh;
  overflow-y: auto;
  padding-right: 2rem;
  border-bottom: 1px solid #aaa;
}
.contact_list_title {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}
.contact_list_item {
  display: flex;
  // margin-bottom: 1rem;
  align-items: center;
  font-weight: 400;
  font-size: 1rem;
  border-bottom: 1px solid #eee;
  white-space: pre;

  & div {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0.5rem;
    display: flex;
    align-items: center;
  }
  &_no {
    // margin-right: 0.7rem;
    width: 2.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #aaa;
  }
  &_img {
    margin-right: 0.5rem;
    width: 2.3rem;
    height: 2.3rem;
    border-radius: 50%;
  }
  &_name {
    cursor: pointer;
    font-weight: 500;
    width: 15rem;
    text-transform: capitalize;
    border-right: 1px solid #aaa;
  }
  &_status {
    width: 15rem;
    // width: 8rem;
    // text-align: center;
  }
  &_viewProfile {
    color: #297bca;
    cursor: pointer;
  }
}

.contact_list_modal {
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  font-weight: 500;
  width: 40rem;
  font-family: 'Roboto';
  font-size: 1.1rem;
}

.contact_list_item-bold {
  font-weight: 500;
  // padding-bottom: 0.25rem;
  border-bottom: 1px solid #aaa;
}
.contact_list_loading {
  height: 8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 400;
}
