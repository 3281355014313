.logsExplorer {
  &__container {
    padding: 2rem;
    font-family: Roboto;
  }
  &__title {
    font-weight: 500;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  &__textfields {
    margin-top: 2rem;
    display: flex;
    align-items: flex-end;
  }
  &__textfield {
    &-label {
      margin-bottom: 0.4rem;
      font-weight: 400;
    }
    margin-right: 1rem;
    font-size: 1rem;
    padding: 0.5rem 0.8rem;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  &__select {
    position: relative;
    z-index: 100;
    width: 20rem;
  }
  &__table {
    margin-top: 2rem;
  }
  &__searchBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    cursor: pointer;
    height: 2.3rem;
    border: 1px solid #aaa;
    border-radius: 10px;
  }
}

.deactivated-users-container {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 4px;
  width: fit-content;
  margin: auto;
}

.deactivated-users-title {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.deactivated-users-count {
  font-size: 24px;
  color: #333;
  font-weight: bold;
}

