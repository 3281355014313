.container {
    padding: 1.5rem;
    font-family: Roboto;
  }
  
  .container3 {
    padding: 1.5rem;
    font-family: Roboto;
    background-color: #fff;
    margin: 1rem;
    .title2 {
      text-align: center;
    }
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    margin-top: 1.5rem;
  }
  .title {
    font-size: 1.4rem;
    font-weight: 500;
  }
  .subtitle {
    margin-top: 1rem;
    font-size: 1.2rem;
    span,
    a {
      cursor: pointer;
      font-weight: 500;
      color: #2f86ff;
    }
  }
  .chatboxContainer {
    height: 70vh;
    width: 100%;
    max-width: 35rem;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    justify-content: space-between;
    padding: 1rem;
    border-radius: 14px;
  }
  .chatBoxMessages {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    padding: 1rem 1.75rem;
    border-radius: 14px;
    //   background-color: #f5f5f5;
  }
  .messageSent {
    align-self: flex-end;
    background-color: #2f86ff;
    margin-bottom: 1rem;
    max-width: 70%;
    padding: 1rem;
    color: #fff;
  
    position: relative;
    border-radius: 13px;
  }
  .messageSentTime {
    font-size: 0.8rem;
    color: #fff;
    text-align: right;
    margin-left: 0.5rem;
  }
  .messageSentExtra {
    display: flex;
    margin-top: 0.5rem;
    justify-content: flex-end;
    align-items: center;
  }
  .messageReceived {
    align-self: flex-start;
    background-color: #ddefff;
    margin-bottom: 1rem;
    max-width: 70%;
    padding: 1rem;
    color: #333;
    border-radius: 13px;
    position: relative;
  }
  .messageReceivedTime {
    font-size: 0.8rem;
    color: #333;
    text-align: right;
    margin-left: 0.5rem;
  }
  .messageReceivedExtra {
    display: flex;
    margin-top: 0.5rem;
    justify-content: flex-end;
    align-items: center;
  }
  .messageAudioButton {
    position: absolute;
    top: 0rem;
    right: -1.5rem;
    cursor: pointer;
  }
  .messageAudioButton svg {
    width: 1.25rem;
  }
  .summaryContainer {
    font-family: Roboto;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    max-width: 35rem;
    border-radius: 14px;
    padding: 1rem;
    background-color: #fff;
  }
  
  .screeningQuestion {
    font-family: Roboto;
    width: 100%;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
    max-width: 25rem;
    border-radius: 14px;
    padding: 1rem;
    background-color: #fff;
  }
  
  .CandidateStatus{
    color: #fff;
    display: flex;
    width: fit-content;
    margin-top: 20px;
  padding: var(--8, 8px) 32px var(--8, 8px) 31px;
  // justify-content: center;
  // align-items: center;
  gap: 10px; 
  border-radius: 10px;
  background: var(--CTA-Green, #0f0f0f); 
  }
  
  .summaryTitle {
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 14px;
    text-align: center;
    margin-bottom: 1rem;
  }
  
  .summaryText {
    line-height: 1.7;
  }
  .title2 {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .userImages {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .userImg {
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
    width: 12rem;
    border-radius: 6px;
  }
  .interviewScores {
    display: flex;
    gap: 1rem;
  }
  .interviewScoreContainer {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: #fafafb;
    display: flex;
  
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
  
    box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  }
  .interviewScoreTitle {
    font-size: 1rem;
    margin-top: 0.25rem;
    font-weight: 600;
    color: #2f86ff;
    margin-bottom: -1rem;
  }
  .interviewScore {
    font-size: 1.5rem;
    font-weight: 500;
  }
  .interviewScoreCategoryMain {
    background-color: #ddefff;
    padding: 1rem;
    padding-top: 0.5rem;
    margin-top: 1rem;
    border-radius: 14px;
  }
  .interviewScoreCategoryTitle {
    font-size: 1.15rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    color: #2f86ff;
    margin-top: 1rem;
    display: flex;
  }
  .interviewScores2 {
    display: flex;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
  
  .interviewScoreSno {
    color: #444;
    margin-top: 0.35rem;
    font-size: 1.1rem;
    font-weight: 500;
  }
  .interviewScoreLabel {
    color: #333;
    font-size: 1.1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    span {
      margin-left: 0.5rem;
      background-color: #ddefff;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .interviewScoreValue {
    margin-top: 0.5rem;
    color: #444;
    font-size: 1.05rem;
  }
  
  .jobModal {
    font-family: Roboto;
    width: 45rem;
    & > div {
      width: 100%;
      margin: 0;
      max-height: 90vh;
      overflow-y: auto;
    }
  }
  .jobModalContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  