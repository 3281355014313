
:root {
  --hover-darken-factor: 0.85; /* Adjust this value to control the darken effect */

}

.container {
  margin: 0.25rem;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
  width: 100%;
  max-width: 19.5rem;
  background-color: #fafafa;
  max-height:62rem;
  padding: 0.75rem;
  border-radius: 5px;
  font-family: Poppins;
  font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 16px;
}

@media only screen and (min-width: 1240px) {
  .container {
    max-width: 21rem; 
  }
}

@media only screen and (min-width: 1440px) {
  .container {
    max-width: 23rem; 
  }
}
@media only screen and (min-width: 1600px) {
  .container {
    max-width: 26.5rem; 
  }
}




.header {
  border-bottom: 1px solid #000;
}
.details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.type {
  color: #fff;
  background-color: #297bca;
  padding: 0.5rem;
  border-radius: 5px;
  font-size: 15px;
  font-weight: 500;
}

.planTag {
  color: #fff;
  padding: 0.4rem 0.8rem;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  display: inline-block;
  transition: background 0.4s ease, transform 0.2s ease;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.planTag.plus {
  background: linear-gradient(90deg, #ff7f50, #ffcc80); 
  font-size: 14px; 
}

.planTag.classic {
  background: linear-gradient(90deg, #74b9ff, #a29bfe); 
  font-size: 14px; 
}

.planTag.organic {
  background: linear-gradient(90deg, #00b09b, #96c93d); 
  font-size: 16px;
  font-weight: 700;
  border: 2px solid #27ae60; 
}



.type2{
  color: #fff;
  background-color: #297bca;
  padding: 0.3rem;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}

.type3{
  color: #fff;
  background-color: #da5400;
  padding: 0.3rem;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
}



.npsRemark {
  color:#297bca
}

.time {
  font-size: 14px;
  font-weight: 500;
}
.hirerDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}
.hirerDetailsLeft {
  display: flex;
}
.hirerImg {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  margin-right: 1rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}

.hirerName {
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: #297bca;
}
.hirerCompany {
  margin-top: 0.25rem;
  // font-size: 14px;
  // font-weight: 500;
  min-height:2.5rem;
  color: #000;
}
.content {
  padding: 1rem 0;
}
.title {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 0.25rem;
  color: #297bca;
  cursor: pointer;
  // text-shadow: 1px 1px 1px #0000001a;
}

.subtitle {
  min-height: 2rem;
}
.section {
  margin-top: 0.5rem;
}

.section2 {
  margin: 1rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

}
.sectionTitle {
  font-weight: 600;
  margin-bottom: 0.25rem;
}

.sectionContent{
  overflow: auto;
  max-height: 10rem;
  scrollbar-width: thin; 
  scrollbar-color: #888 #e0e0e0; 
}

.sectionContent3{
 overflow: hidden
}
/* For Chrome, Edge, and Safari */

.sectionContent2 {
  overflow: auto;
  height: 3.3rem;
  scrollbar-width: thin; 
  scrollbar-color: #888 #e0e0e0; 
}

.sectionContent2::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.sectionContent2::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #555;
}

.sectionContent2::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
  border: 2px solid #e0e0e0; 
}
.sectionCards {
  display: flex;
  flex-wrap: wrap;
  gap: 0.2rem;
}
.sectionCard {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 4px;
  background-color: #297bca;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  // border: 0.2px solid #297bca;
}
.sectionCardVariant2 {
  background-color: #fff;
  color: #297bca;
}
.footer {
  border-top: 1px solid #000;
  padding-top: 1rem;
}
.buttons {
  position: sticky;
  display: flex;
  justify-content: space-around;
  gap: 4px;
  & > button {
    padding: 0.35rem 0.5rem;
    font-size: 12px;
    font-weight: 500;
    border-radius: 4px;
    width:100%;
    cursor: pointer;
  }
}
.dangerButton {
  color: #fff;
  background-color: #ff5a4f;
  border: 2px solid #ff5a4f;

  text-align: center;


}
.dangerButtonAlt {
  background-color: #fff;
  color: #ff5a4f;
  border: 2px solid #ff5a4f;

  text-align: center;

/* Regular/Text/TRegular3 */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */
}

.primaryButton {
  border-radius: 4px;
border: 2px solid var(--Foundation-State-State---Info, #297BCA);
background: var(--Foundation-State-State---Info, #297BCA);

color: var(--Foundation-Neutral-White, #FFF);
text-align: center;

/* Regular/Text/TRegular3 */
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; /* 133.333% */

}
.primaryButtonAlt {
  border-radius: 4px;
border: 2px solid var(--Foundation-State-State---Info, #297BCA);
background: var(--Foundation-Neutral-White, #FFF);

color: var(--Foundation-State-State---Info, #297BCA);
text-align: center;

font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
}

.onHoldButton {
  background-color: #676767;
  color: #ffffff;
  border: 2px solid #656565;
  
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px; 
}


.dangerButton, .dangerButtonAlt, .primaryButton, .primaryButtonAlt, .onHoldButton {
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}

.dangerButton:hover{
  background-color: rgba(255, 90, 79, var(--hover-darken-factor));
  border-color: rgba(255, 90, 79, var(--hover-darken-factor));
}

.primaryButton:hover{
  background-color: rgba(41, 123, 202, var(--hover-darken-factor));
  border-color: rgba(41, 123, 202, var(--hover-darken-factor));
}

.onHoldButton:hover {
  background-color: rgba(103, 103, 103, var(--hover-darken-factor));
  border-color: rgba(101, 101, 101, var(--hover-darken-factor));
}

.dangerButtonAlt:hover {
  background-color: #ff5a4f; /* Border color becomes the background color */
  color: #fff; /* Text color changes to ensure visibility */
}

/* Updated hover effect for .primaryButtonAlt */
.primaryButtonAlt:hover {
  background-color: var(--Foundation-State-State---Info, #297BCA); /* Border color becomes the background color */
  color: var(--Foundation-Neutral-White, #FFF); /* Text color changes to ensure visibility */
}

.dangerButtonAlt, .primaryButtonAlt {
  transition: background-color 0.3s ease, color 0.3s ease;
}
.title2 {
  margin-bottom: 2rem;
}
.rejectReason {
  margin-bottom: 0.5rem;
  font-size: 15px;
  font-weight: 500;
  color: #313131;
  strong {
    color: #ff5a4f;
  }
}

.feedbackBtn {
  background-color: #861cdd;
  cursor: pointer;
  border-radius: 60%;
  padding: 5px;
  color: #ffff;
  border: none;
}

.hirerJobCount {
  border: 4px solid #297bca;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #297bca;
  font-weight: 600;
  width: 2.75rem;
  height: 2.75rem;
  border-radius: 50%;
  font-size: 16px;
}

.applicationsCount {
  display: flex;
  line-height: 1;
  align-items: center;
}
.viewApplicationsLength {
  margin-left: 0.5rem;
  font-size: 15px;
  font-weight: 600;
  color: #297bca;
  cursor: pointer;
}
.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.switchLabel {
  & * {
    transition: 0.5s;
  }
  width: 40px;
  min-width: 40px;
  display: block;
  margin-bottom: 1rem;
  cursor: pointer;
  height: 20px;
  min-height: 20px;
  background-color: #d55050;
  box-shadow: inset 0px 3px 0px 0px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  z-index: 2;
}

.switchInput:checked ~ .switchLabel {
  background-color: #50d5a1;
}

.switchInput:checked ~ .switchLabel div.switchContainer {
  margin-left: 18px;
}

.switchLabel div.switchContainer {
  height: 12px;
  width: 18px;
  // height: 100%;
  background-color: #fafafa;
  border-radius: 50px;
  overflow: hidden;
  position: relative;
  top: 2.5px;
  left: 2px;
  box-shadow: 0px 3px 0px 0px #d2d2d2;
  cursor: pointer;
}

.switchLabel div div.switchItem {
  width: 7px;
  height: 7px;
  background-color: #ebebeb;
  position: relative;
  border-radius: 2.5px;
  box-shadow: inset 0px 1.5px 0px 0px rgba(0, 0, 0, 0.2);
  position: absolute;
}

.switchLabel div div.switchItem:nth-child(1) {
  left: 18px;
  top: 15px;
}

.switchLabel div div.switchItem:nth-child(2) {
  left: 32px;
  top: 15px;
}

.switchLabel div div.switchItem:nth-child(3) {
  left: 46px;
  top: 15px;
}

.switchLabel div div.switchItem:nth-child(4) {
  left: 18px;
  top: 28px;
}

.switchLabel div div.switchItem:nth-child(5) {
  left: 32px;
  top: 28px;
}

.switchLabel div div.switchItem:nth-child(6) {
  left: 46px;
  top: 28px;
}

.switchLabel div div.switchItem:nth-child(7) {
  left: 18px;
  top: 42px;
}

.switchLabel div div.switchItem:nth-child(8) {
  left: 32px;
  top: 42px;
}

.switchLabel div div.switchItem:nth-child(9) {
  left: 46px;
  top: 42px;
}

.switchInput {
  display: none;
  appearance: none;
}

.rightBtns {
display: flex;
justify-content: center;
align-items: center;
flex-direction: row;
gap: 10px;
}


.dropdown {
  position: relative;
  display: inline-block;
  .primaryButton {
    padding: 0.35rem 0.5rem;
  }

  
}

.dropbtn {
 background-color: transparent;
  //  font-size: 15px;
  border: none;
  cursor: pointer;

}

.dropdownContent {
   display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 200px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 999;
  border-radius: 5px;
  cursor: pointer;
  padding: 0.5rem;
  bottom: 105%;
}

.approveDropdownContent {
  z-index: 999;
 min-width: 135px;

}

.icon {
  margin-right: 8px; // Adjust the space between the icon and text as needed
}

.dropdownContent a {
  display: flex;
  align-items: center;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-radius: 5px;
  &:hover {
    background-color: #ddd;
  }
}

.dropdown:hover .dropdownContent {
  display: block;
}

// .dropdown:hover .dropbtn {
//   background-color: #ccc;
//   padding: 0.5rem;
//   border-radius: 50%;
// }

.dropdownLinkDisabled {
  color: #ccc;
  cursor: not-allowed;
  &:hover {
    background-color: #f9f9f9; // Keep the background the same as the default state to indicate unclickability
  }
}

.threeDots {
  // Style the three dots SVG icon here
  vertical-align: middle;
  fill: currentColor;
}

.tooltipIconContainer {
  margin-left: 10px;
  position: relative;
  display: inline-block;
  cursor: pointer;

  &:hover .tooltipText {
    visibility: visible;
    opacity: 1;
  }
}

.tooltipText {
  visibility: hidden;
  width: 120px;
  font-size: 16px;
  background-color: #297bca;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  padding: 5px 0;
  
  /* Positioning */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  transform: translateY(90%) translateX(10%);
  margin-bottom: 5px;

  /* Fade in */
  opacity: 0;
  transition: opacity 0.3s;
}

.candidateTable {
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  border-collapse: collapse; // Ensures that borders collapse into a single border when they meet
  background-color: #fff; // Sets a white background for the table for better contrast
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); // Adds a subtle shadow for depth
  border-radius: 8px; // Rounds the corners of the entire table
  overflow: hidden; // Ensures the border radius clips the content
}

.tableRow {
  display: contents;
}

.tableCell {
  padding: 0.5rem 1rem;
  width: 12rem;
  border-bottom: 1px solid #eaeaea;
  
  &:first-child {
    font-weight: 700;
    color: #666;
    background-color: #f9f9f9;
    border-right: 1px solid #eaeaea; 
  }

  // Target the second child specifically for styling non-header cells
  &:nth-child(2) {
    font-weight: 500;
    cursor: pointer; // Change cursor to pointer
    &:hover {
      text-decoration: underline; // Underline on hover
    }
  }

  &:last-child {
    text-align: right; 
  }

  &:not(:last-child) {
    border-bottom-color: transparent; 
  }
}

.valueCell {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #eaeaea;
text-align: right;
    font-weight: 500;
    cursor: pointer; // Change cursor to pointer
    &:hover {
      text-decoration: underline; // Underline on hover
    }

}
.valueCell2 {
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid #eaeaea;
text-align: right;
    font-weight: 500;
  

}
.tableRow:last-child .tableCell {
  border-bottom: none;
  
}

.viewLink {
  display: inline-block; // Use inline-block for better hover behavior
  margin-left: 0.5rem;
  color: #297bca;
  text-decoration: none;
  cursor: pointer; // Ensure it's clear this is a clickable link
  
  &:hover {
    text-decoration: underline;
  }
}

.na {
  padding: 0.5rem 1rem;
  border-bottom: 1px solid #eaeaea;
  color:#666;
text-align: right;
    font-weight: 500;
  &:hover {
    text-decoration: none;
  }
}



.modalContent {
  z-index: 1051;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 900px;
  width: 90%;
  background: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  max-height: 80vh;
}

.modalSubHeading {
  font-family: Poppins;
  font-size: 14px;
  color: #000000;
}

.modalHeading {
  font-family: Poppins;
  font-size: 16px;
  color: #000000;
}
.modalTextContent {
  background: #eee;
  padding: 4px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 12px;
  overflow: auto;
  max-height: 8rem;
  scrollbar-width: thin; 
  scrollbar-color: #888 #e0e0e0; 
  border: 1px solid #ccc;
  border-radius: 12px; 
}

.modalBtnCancel {
  font-family: Poppins;
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  float: right;

  &:hover {
    background-color: #c82333;
  }
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}


.modalTextContent {
  background: #eee;
  padding: 10px;
  margin-bottom: 10px;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 12px;
  overflow: auto;
  max-height: 8rem;
  scrollbar-width: thin; 
  scrollbar-color: #888 #e0e0e0; 
  border: 1px solid #ccc;
  border-radius: 12px; 

  &.markdownContentContainer {
    max-height: 85vh !important;
    height: fit-content !important;
  }

  & > * {
    margin: 0;
    padding: revert-layer;
  }
}

.jdOverLay {
  width: 50rem;
}