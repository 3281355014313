.title {
  font-size: 18px;
  margin-bottom: 1rem;
}
.textField {
  margin: 1rem 0;
  width: 100%;
  border: 1px solid #aaa;
  font-size: 1rem;
  padding: 0.65rem 1rem;
  border-radius: 4px;
}
.buttons {
  margin-top: 1rem;
}
