.container {
  padding: 1.5rem;
  font-family: Roboto;
}
.title {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 0.25rem;
}
.mutualIcon {
  width: 15px;
  margin-right: 0.25rem;
}
.userContactSearch {
  display: flex;
  margin-top: 1rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  border-radius: 4px;
  width: 24rem;
  margin-bottom: 1rem;
  input {
    font-size: 1rem;
    border: none;
    padding: 0.65rem 1rem;
    width: calc(100% - 5rem);
    &:focus {
      outline: none;
    }
  }
  button {
    border: none;
    width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4789fc;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
  }
}
.userContactSearchMobile {
  display: flex;
  margin-top: 1rem;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%),
    0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  overflow: hidden;
  border-radius: 4px;
  width: 15rem;
  margin-left: 2rem;
  margin-bottom: 1rem;
  input {
    font-size: 1rem;
    border: none;
    padding: 0.65rem 1rem;
    width: calc(100% - 4rem);
    &:focus {
      outline: none;
    }
  }
  button {
    border: none;
    width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #4789fc;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
  }
}
