.customScrollbar {
    width: auto;
    height: 40px;
    min-height: 40px;
    overflow-y: 200px;
  
    &::-webkit-scrollbar {
      width: 12px;
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 10px;
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  
  .timeDropdown {
    display: flex;
    flex-direction: column;
  }
  