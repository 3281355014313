
.candidateListTitleContainer {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .menuItem {
    display: flex;
    gap: 15px;
    align-items: center;
  }
  
  /* From Uiverse.io by SelfMadeSystem */ 
  /* The switch - the box around the slider */
  .switch {
    --a: 0.5s ease-out;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    border-radius: 2em;
    box-shadow: 0 0 0 0.4em #aaa;
    aspect-ratio: 212.4992/84.4688;
    background-color: #aaa;
  }
  
  /* Hide default HTML checkbox */
  #check {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .switch svg {
    width: 2.5rem;
  }
  
  .switch svg path {
    color: #fff;
    stroke-width: 16;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 136 224;
    transition:
      all var(--a),
      0s transform;
    transform-origin: center;
  }
  
  #check:checked ~ .switch svg path {
    stroke-dashoffset: 180;
    transform: scaleY(-1);
  }

  
.feedbackBtn {
    background-color: #861cdd;
    cursor: pointer;
    border-radius: 60%;
    padding: 5px;
    color: #ffff;
    border: none;
}
  

.tooltipIconContainer {
    margin-left: 10px;
    position: relative;
    display: inline-block;
    cursor: pointer;
  
    &:hover .tooltipText {
      visibility: visible;
      opacity: 1;
    }
  }
  
  .tooltipText {
    visibility: hidden;
    width: 120px;
    font-size: 16px;
    background-color: #297bca;
    color: #fff;
    text-align: center;
    border-radius: 10px;
    padding: 5px 0;
    
    /* Positioning */
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateY(90%) translateX(10%);
    margin-bottom: 5px;
  
    /* Fade in */
    opacity: 0;
    transition: opacity 0.3s;
}

.modalHeading {
    font-family: Poppins;
    font-size: 16px;
    color: #000000;
}

.modalTextContent {
    background: #eee;
    padding: 4px;
    margin-bottom: 10px;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 12px;
    overflow: auto;
    max-height: 8rem;
    scrollbar-width: thin; 
    scrollbar-color: #888 #e0e0e0; 
    border: 1px solid #ccc;
    border-radius: 12px; 
}

.modalContent {
  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 10px;

  & > * {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.headingModal {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.modalContainer {
  background-color: #fff;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}