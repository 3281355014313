
.container {
    display: flexbox;
    justify-content: flex-start;
    width: 60%;
    margin-bottom: 20px;
  }
  
  .formContainer {
    display: flexbox;
    justify-content: flex-start;
    width:50%;
    margin-bottom: 20px;
  }
  
  .dropdown {
    width: 200px; 
  }
  
  .custom-date-container {
    display: flexbox;
    align-items: flex-start;
    gap: 50px;
  }
  
  .button {
    margin-top: 8px;
  }
  