.everyTable {
  &__title {
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 500;
    font-family: Poppins;
  }
  &__table {
    padding: 1rem;
    &-2 {
      font-family: Poppins;
      max-width: 50%;
      padding: 0rem;
      padding-bottom: 3rem;
      margin: 1rem;
      .ReactTable {
        background-color: #fff;
      }
    }
  }
}

.materialTableModal {
  height: 500px;
  overflow: scroll;
}