.overlay-class {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  height: 100vh;
  background-color: rgba(59, 59, 59, 0.616);
}
.modal-class {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30rem;
  transform: translate(-50%, -50%);
}

.modal-class:focus {
  outline: none;
}
.modalContainer {
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  font-weight: 500;
  font-family: 'Roboto';
  font-size: 1.1rem;
}
.modalBtns {
  margin-top: 0.75rem;
}
.modal-accept {
  margin-right: 2rem;
  font-size: 1rem;
  border: 1px solid #0e73e7;
  background-color: #0e73e7;
  color: #fff;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 0.35rem 1rem;
  cursor: pointer;
}
.modal-cancel {
  /* margin-right: 5rem; */
  font-size: 1rem;
  border: 1px solid #333;
  background-color: #fff;
  color: #000;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 0.35rem 1rem;
  cursor: pointer;
}
/* }
  
    &-btns {
    }
    &-btn {
      &-reject {
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #ff4e4e;
        background-color: #ff4e4e;
        color: #fff;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
      &-cancel {
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #333;
        background-color: #fff;
        color: #000;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
    }
  } */

  .remarks__container {
    margin-top: 5px;
    max-height: 300px;
    overflow-y: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .remarks-header {
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  .remark__item {
    margin-bottom: 10px;
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .remark__admin-name {
    font-weight: bold;
  }
  
  .remark__text {
    margin-top: 5px;
    font-weight: 300;
  }
  
  .remark__timestamp {
    float: right;
    font-size: 0.8em;
    color: #999;
    margin-bottom: 5px;
  }
  .remark-input-container {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
  }
  
  .remark-input-box {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    padding: 10px;
    margin-top: 5px;
  }
  
  .remark-input {
    flex-grow: 1;
    border: none;
    border-radius: 5px;
    padding: 10px;
    font-size: 1em;
    outline: none;
    resize: none;
  }

  .important-toggle {
    position: absolute;
    bottom: 0.75rem;
    left: 1.125rem; 
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .rnr-label {
    color: #FFBF00;
    font-weight: bold;
    margin-left: 8px;
  }
  
  .send-button {
    border: none;
    background: none;
    cursor: pointer;
    margin-left: 10px;
  }
  
  .send-button:hover {
    opacity: 0.8;
  }
  
  .remark-input:focus {
    border: 1px solid #297bca;
  }
  

  .toast {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #297bca;
    color: #fff;
    padding: 15px 20px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 16px;
    z-index: 1000;
    font-family: Poppins;
    animation: fadeInOut 3s ease-in-out;
  }
  
  .toast.error {
    background-color: #ff4e4e;
  }
  
  .toast.success {
    background-color: #297bca;
  }
  


  .last-follow-up {
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .last-follow-up-admin {
    font-weight: bold;
  }
  
  .last-follow-up-timestamp {
    font-size: 0.8em;
    color: #999;
  }
  

  .org__data-item-value .Select__control {
    border-radius: 5px;
    border: 1px solid #ccc;
    background-color: #f9f9f9;
    padding: 5px;
    font-size: 1em;
  }
  
  .org__data-item-value .Select__control:hover {
    border-color: #297bca;
  }
  
  .org__data-item-value .Select__menu {
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .org__data-item-value .Select__option--is-selected {
    background-color: #297bca;
    color: #fff;
  }
  
  .org__data-item-value .Select__option--is-focused {
    background-color: #ddd;
  }
  

  .sales-stage-admin {
    font-weight: bold;
    margin-top: 5px;
  }
  
  .sales-stage-date {
    color: #999;
    font-size: 0.8em;
    margin-top: 2px;
  }

  .no-follow-up-checkbox {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .no-follow-up-checkbox input {
    margin-right: 5px;
  }
  

  .large-label {
    font-size: 1.5rem;
  }
  

  .next-follow-up {
    margin-top: 10px;
    font-weight: bold;
    color: #ff4e4e; /* Dominant color for follow-up date */
  }
  
  .next-follow-up-date {
    display: block;
    margin-top: 5px;
    color: #297bca;
  }
  
  
  .revenue-update-container {
    display: flex;
    flex-direction: column;
  }
  
  .input-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .currency-select {
    flex: 1;
    margin-right: 5px;
  }
  
  .revenue-input {
    flex: 2;
  }
  
  .update-revenue-btn {
    width: 50%; 
    height: 40px;
    font-size: 14px; 
    color: #fff;
    background-color: #297bca; 
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .update-revenue-btn:hover {
    background-color: #1e5a94; 
  }
  
  .hiring-targeted-job-section {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 10px;
    width: 100%;
  }
  
  .hiring-frequency-field,
  .targeted-job-profiles-field {
    display: flex;
    flex-direction: column;
  }
  
  .hiring-frequency-field label,
  .targeted-job-profiles-field label {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    margin-bottom: 4px;
  }
  
  .hiring-frequency-field input,
  .targeted-job-profiles-field input {
    padding: 8px 12px;
    font-size: 14px;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    transition: border-color 0.3s;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .hiring-frequency-field input:focus,
  .targeted-job-profiles-field input:focus {
    border-color: #297bca;
    outline: none;
  }
  
  .update-hiring-targeted-btn {
    padding: 6px 16px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    background-color: #297bca;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    align-self: flex-start;
    transition: background-color 0.3s;
  }
  
  .update-hiring-targeted-btn:hover {
    background-color: #005bb5;
  }
  
  
  .update-hiring-frequency-btn,
.update-targeted-job-profiles-btn {
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 500;
  color: #ffffff;
  background-color: #297bca;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 8px;
  width: fit-content;
  transition: background-color 0.3s;
}

.update-hiring-frequency-btn:hover,
.update-targeted-job-profiles-btn:hover {
  background-color: #005bb5;
}
