.container {
  display: flex;
  border: 1px solid #389fff;
  margin-left: 1rem;
  border-radius: 21px;
  padding: 0.25rem 0.5rem;
  background-color: #fff;
  align-items: center;
}
.playPauseButton {
  width: 23px;
  height: 23px;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  //   padding: 0.1rem;
  cursor: pointer;
  svg {
    width: 55%;
  }
}
.seekBar {
  width: 13rem;
  margin: 0 0.6rem;
  margin-left: 0.8rem;
  display: flex;
  align-items: center;
}
