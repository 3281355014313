#eds390quKSE2_to {
  animation: eds390quKSE2_to__to 1020ms linear infinite normal forwards;
}
@keyframes eds390quKSE2_to__to {
  0% {
    transform: translate(2.482257px, 43.25301px);
  }
  3.921569% {
    transform: translate(2.482257px, 43.25301px);
  }
  78.431373% {
    transform: translate(2.482257px, 43.25301px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
  }
  88.235294% {
    transform: translate(2.482257px, 46.25301px);
  }
  98.039216% {
    transform: translate(2.482257px, 43.25301px);
  }
  100% {
    transform: translate(2.482257px, 43.25301px);
  }
}
#eds390quKSE2_tr {
  animation: eds390quKSE2_tr__tr 1020ms linear infinite normal forwards;
}
@keyframes eds390quKSE2_tr__tr {
  0% {
    transform: rotate(0deg);
  }
  3.921569% {
    transform: rotate(0deg);
  }
  11.764706% {
    transform: rotate(6deg);
  }
  19.607843% {
    transform: rotate(-26deg);
  }
  27.45098% {
    transform: rotate(4deg);
  }
  31.372549% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
#eds390quKSE3_to {
  animation: eds390quKSE3_to__to 1020ms linear infinite normal forwards;
}
@keyframes eds390quKSE3_to__to {
  0% {
    transform: translate(20.45px, 21.85px);
  }
  19.607843% {
    transform: translate(20.45px, 21.85px);
  }
  49.019608% {
    transform: translate(20.45px, -2.15px);
  }
  78.431373% {
    transform: translate(20.45px, 21.85px);
  }
  88.235294% {
    transform: translate(20.45px, 24.85px);
  }
  98.039216% {
    transform: translate(20.45px, 21.85px);
  }
  100% {
    transform: translate(20.45px, 21.85px);
  }
}
#eds390quKSE3_tr {
  animation: eds390quKSE3_tr__tr 1020ms linear infinite normal forwards;
}
@keyframes eds390quKSE3_tr__tr {
  0% {
    transform: rotate(0deg);
  }
  19.607843% {
    transform: rotate(0deg);
  }
  78.431373% {
    transform: rotate(-1080deg);
  }
  100% {
    transform: rotate(-1080deg);
  }
}
