.container {
  padding: 1.5rem;
  font-family: Roboto;
}
.title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 2rem;
}
.label {
  margin-bottom: 1rem;
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}
.item {
  margin-top: -0.5rem;
  width: 75%;
  margin-bottom: 2rem;
}
.button {
  margin-top: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14rem;
  height: 2.3rem;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  padding: 0.25rem;
  background-color: #1665ee;
}
