.reportGen {
  &__container {
    padding: 1.5rem;
    font-family: Roboto;
  }

  &__title {
    font-size: 2.1rem;
    font-weight: 500;
  }

  &__period {
    &-filter {
      margin-top: 1rem;

      &-label {
        color: #546e7a;
        font-size: 14px;
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: -0.04px;
      }

      &-select {
        width: 14rem;
      }
    }
  }

  &__section {
    &-container {
      margin-top: 2rem;
    }

    &-label {
      font-size: 1.3rem;
      font-weight: 500;
    }

    &-data {
      padding-top: 1rem;
      align-items: center;
      justify-content: flex-start;
      display: flex;
    }
  }

  &__table {
    &-container {
      min-width: 25rem;
      width: 40%;
    }
    &-container2 {
      min-width: 45rem;
      width: 30%;
    }
  }

  &__chart {
    &-container {
      width: 27rem;
      overflow: visible;
      margin: 0 auto;
      margin-top: -5rem;
      margin-bottom: -4rem;

      &-2 {
        width: 25rem;
      }
    }
  }
}