.addCampaign__text {
  font-size: 1.3rem;
  font-family: Roboto;
  margin-top: 2rem;
}
.addNewCampaign_container {
  font-family: Roboto;
  /* box-shadow: 1px 2px 6px rgb(0 0 0 / 16%); */
  width: 30rem;
  margin: 2rem 0;
  /* border-radius: 10px; */
  /* background-color: #fff; */
  /* padding: 1rem; */
}
.addNewCampaign_title {
  font-size: 1.4rem;
  color: #2656f5;
}
.addNewCampaign_label {
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.addNewCampaign_input {
  width: 100%;
  font-size: 1.1rem;
  padding: 0.25rem;
  border-radius: 5px;
  border: 1px solid #999;
}
.addNewCampaign_input:focus {
  outline: none;
}
.addNewCampaign_btn {
  background-color: #2656f5;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13rem;
  border: none;
  margin: 1rem auto;
  height: 2rem;
  cursor: pointer;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.16);
  font-size: 1rem;
  border-radius: 6px;
}
.campaign_link {
  color: #2656f5;
  font-weight: 500;
}
.addNewCampaign_error {
  text-align: center;
}
.campaignText {
  font-size: 1.1rem;
  margin-left: 2rem;
}
.campaignText:first-child {
  margin-left: 0;
}
.customtable {
  &__super {
    background-color: #fff;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    padding: 0.5rem;
  }
  &__container {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 4px;
    }
    border-top: 1px solid #d7d7d7;
    align-items: flex-start;
    overflow-x: scroll;
    display: flex;
    width: 100%;
    min-height: 30rem;
    height: fit-content;
    // padding: 0.5rem;
    font-family: Roboto;
    padding-bottom: 0;
  }
  &__title {
    font-family: Roboto;
    padding: 1rem;
    font-size: 0.95rem;
  }
  &__column {
    // padding: 0 0.2rem;
    // border: 1px solid #000;
    border-bottom: none;
    border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;

    &-column {
      // width: 1rem;
      border-left: 1px solid #d7d7d7;
      border-right: 1px solid #d7d7d7;
      border-bottom: none;
      // margin: 0 1rem;
      // padding-right: 1rem;
    }
    &-grouped {
      // padding: 0 0.2rem;
    }
    & &-header {
      padding: 0 1rem;
      padding-top: 2.1rem;
      padding-bottom: 0.5rem;
    }
    &-header {
      border-bottom: 1px solid #d7d7d7;
      white-space: pre;

      padding: 0 1rem;
      padding-bottom: 0.45rem;
      padding-top: 0.45rem;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
      color: #263238;
      font-size: 14px;
      text-align: left;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: -0.05px;
    }
    &-header {
      &-grouped {
        &-main {
          text-align: center;
          font-weight: 500;
          // letter-spacing: -0.05px;
          line-height: 19px;
          font-size: 14px;
          border: 1px solid #d7d7d7;
          border-top: none;
          padding: 0.25rem 0;
        }
        &-others {
          display: flex;
          justify-content: space-around;
        }
      }
    }
    &-body {
      &-item {
        font-size: 0.9rem;
        // margin-bottom: 1rem;
        border-bottom: 1px solid #d7d7d7;
        min-height: 3.3rem;
        display: flex;
        align-items: center;
        padding: 0 1rem;
        font-weight: 400;
        white-space: pre;
        &-center {
          padding-left: 1.5rem;
          justify-content: center;
          padding-right: 1.5rem;
          text-align: center;
        }
      }
    }
  }
  &__paginate {
    display: flex;
    justify-content: flex-end;
    padding-right: 2rem;
    align-items: center;
    font-size: 14px;
    font-family: Roboto;
    color: #263238;
    &-first,
    &-prev,
    &-last,
    &-next {
      cursor: pointer;
      margin: 0 0.2rem;
      color: #555;
      display: flex;
      align-items: center;
      justify-content: center;
      &-disabled {
        color: #aaa;
        cursor: default;
      }
    }
  }
}
