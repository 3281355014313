.org {
  &__container {
    padding: 0.5rem 0;
    font-family: 'Roboto';
    display: flex;
    &-left {
      width: 50%;
    }
    &-right {
      width: 50%;
      padding-right: 0.5rem;
      textarea {
        padding: 1rem;
        font-size: 1rem;
        font-family: Roboto;
        resize: none;
        border-radius: 7px;
        &:focus {
          outline: none;
        }
        width: 100%;
        height: 100%;
      }
    }
  }

  &__data {
    &-container {
      max-width: 44rem;
    }
    &-row {
      display: flex;
      justify-content: space-between;
    }
    &-item {
      margin-bottom: 0.75rem;
      padding: 0 1rem;
      width: 20rem;
      //   border: 1px solid red;
      &-label {
        font-weight: 600;
      }
      &-value {
        margin-top: 0.2rem;
        & input {
          font-size: 1rem;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #cccccc;
          padding: 0.45rem 0.75rem;
        }
      }
    }
  }
  &__buttons {
    margin-top: 1rem;
    &-btn {
      cursor: pointer;
      background-color: #fff;
      border: 1px solid #000;
      padding: 0.3rem 0.65rem;
      font-size: 0.95rem;
      margin-right: 1.5rem;
      border-radius: 4px;
      &-action {
        background-color: #297bca;
        color: #fff;
        border-color: #297bca;
      }
      &-edit {
        background-color: #fff;
        color: #297bca;
        border-color: #297bca;
      }
      &-delete {
        background-color: #fff;
        color: #ff4027;
        border-color: #ff4027;
      }
    }
  }
}
.preference__list {
  &-item {
    display: flex;
    margin-bottom: 0.75rem;
    &-no {
      margin-right: 0.5rem;
    }
    &-label {
      font-weight: 500;
    }
  }
}