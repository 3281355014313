.com {
  &_container {
    padding: 1rem;
    font-family: Roboto;
  }
  &_title {
    font-size: 1.1rem;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 0.5rem;
  }
  &_data {
    margin-bottom: 1rem;
    &-grp {
      display: flex;
    }
    &-key {
      margin-right: 0.5rem;
      font-weight: 500;
    }
  }
}
