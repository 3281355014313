.container {
  padding: 1rem;
  font-family: Poppins;
  width: 50%;
  padding-top: 0;
  @media screen and (max-width: 955px) {
    width: 100%;
  }
}
.userDetails {
  align-items: center;

  display: flex;
  text-transform: capitalize;
}
.userName {
  font-weight: 500;
  font-size: 1.5rem;
}
.userPfp {
  margin-right: 1rem;
  width: 80px;
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.sectionContainer {
  margin-top: 1rem;
}
.sectionTitle {
  font-weight: 500;
  font-size: 1.1rem;
}
.userImagesContainer {
  display: flex;
  flex-wrap: wrap;
  max-width: 450px;
  @media screen and (max-width: 955px) {
    max-width: none;
  }
}
.userImg {
  margin-top: 0.5rem;
  width: 140px;
  height: 140px;
  border-radius: 9px;
  overflow: hidden;
  margin-right: 10px;
  img {
    width: 100%;
  }
}
.sectionValue {
  font-size: 15px;
}
.sectionItemLabel {
  font-weight: 500;
  font-size: 15px;
  margin-right: 0.4rem;
}
.sectionItemValue {
  font-size: 15px;
  text-transform: capitalize;
}

.disabled {
  pointer-events: none;
  cursor: not-allowed;

  filter: blur(20px);
  background-color: black;
  overflow: hidden;
}