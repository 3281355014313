.createPaymentLink__title {
    font-size: 1.7rem;
    font-weight: 500;
    font-family: Roboto;
  }
  
  .createPaymentLink__text {
    font-size: 1.4rem;
  color: #2656f5;
  margin-bottom: 40px;
  }
  
  .createNewPayment_container {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  
  .createNewPayment_label {
    font-size: 16px;
    margin-bottom: 8px;
    font-family: Roboto;
  }
  
  .createNewPayment_input {
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 300px;
  }
  
  .createNewPayment_input:focus {
    outline: none;
    border-color: #2656f5;
    box-shadow: 0 0 5px rgba(38, 86, 245, 0.5);
  }
  
  .createNewPayment_button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background-color: #2656f5;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: fit-content;
  }
  
  .createNewPayment_button:disabled {
    background-color: #b3c7f9;
    cursor: not-allowed;
  }
  
  .createNewPayment_button:hover:enabled {
    background-color: #1e46d1;
  }
  
  .createPaymentLink__result {
    margin-top: 20px;
    font-size: 16px;
  }
  
  .createPaymentLink__result a {
    color: #2656f5;
    text-decoration: none;
  }
  
  .createPaymentLink__result a:hover {
    text-decoration: underline;
  }
  
  .createPaymentLink__error {
    margin-top: 20px;
    color: red;
    font-size: 16px;
  }
  