.select_container {
  display: flex;
  align-items: center;
  /* font-size: 11rem; */
  font-family: 'Roboto';
  margin-top: 0.6rem;
  margin-left: 2rem;
}
.select_empty_space {
  position: relative;
  width: 15rem;
  margin-left: 1rem;
}
.custom-select__control {
  width: 15rem;
  position: absolute;
  /* margin-left: 1rem; */
}
.MTableHeader-header-43 {
  z-index: 0 !important;
}
.custom-select__menu {
  z-index: 99999;
  /* position: relative; */
}
.sort_clear_btn {
  padding: 0.4rem 1rem;
  margin-left: 2rem;
  font-weight: 500;
  background-color: #2656f5;
  color: #fff;
  border: none;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.16);
  font-size: 1rem;
  border-radius: 6px;
  cursor: pointer;
}
.sort_clear_btn-disabled {
  background-color: #fff;
  color: #838383;
  cursor: not-allowed;
}
.table__pagination-default-container {
  width: 20rem;
}
.table__pagination-goToPage {
  border: 1px solid #a0a0a0;
  border-radius: 3px;
  margin-left: 0.5rem;
  margin-right: 1rem;
  display: flex;
  align-items: center;
}
.table__pagination-goToPage input {
  border: none;
  width: 5rem;
  font-size: 0.9rem;
  background-color: #fff;
  padding: 0.2rem 0.25rem;
}
.table__pagination-goToPage input:focus {
  outline: none;
}
.table__pagination-goToPage-btn {
  height: 100%; /* background-color: #fff; */
  border: none;
  padding: 0.2rem 0.5rem;
  background-color: #222;
  cursor: pointer;
  color: #fff;
  border-top-right-radius: 3px;
  font-size: 0.9rem;
  border-bottom-right-radius: 3px;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sm-width {
  min-width: 10rem;
}
.remarks-input {
  width: 14rem;
  padding: 0.2rem 0.2rem;
  font-size: 0.9rem;
}
.exportToCsvBtn {
  border: 1px solid #2a99f5;
  position: absolute;
  left: 1rem;
  cursor: pointer;
  border-radius: 4px;
  background-color: #3ca7ff;
  color: #fff;
  display: flex;
  align-items: center;
  font-family: Roboto;
  padding: 0.2rem 0.4rem;
}
.exportToCsvBtn span {
  margin-right: 0.5rem;
}
