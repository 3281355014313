.overlay-class {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  height: 100vh;
  background-color: rgba(59, 59, 59, 0.616);
}
.modal-class {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30rem;
  transform: translate(-50%, -50%);
}

.modal-class:focus {
  outline: none;
}
.modalContainer {
  background: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  font-weight: 500;
  font-family: 'Roboto';
  font-size: 1.1rem;
}
.modalBtns {
  margin-top: 0.75rem;
}
.modal-accept {
  margin-right: 2rem;
  font-size: 1rem;
  border: 1px solid #0e73e7;
  background-color: #0e73e7;
  color: #fff;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 0.35rem 1rem;
  cursor: pointer;
}
.modal-cancel {
  /* margin-right: 5rem; */
  font-size: 1rem;
  border: 1px solid #333;
  background-color: #fff;
  color: #000;
  box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 0.35rem 1rem;
  cursor: pointer;
}
/* }
  
    &-btns {
    }
    &-btn {
      &-reject {
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #ff4e4e;
        background-color: #ff4e4e;
        color: #fff;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
      &-cancel {
        margin-right: 5rem;
        font-size: 1rem;
        border: 1px solid #333;
        background-color: #fff;
        color: #000;
        box-shadow: 1px 3px 6px rgba(0, 0, 0, 0.16);
        border: none;
        border-radius: 4px;
        padding: 0.35rem 1rem;
        cursor: pointer;
      }
    }
  } */
.purposeContainer {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5rem;
  font-weight: 500;
  font-family: 'Roboto';
  font-size: 1.05rem;
}
.purposeLabel {
  margin-bottom: 0.5rem;
}
.purposeButton {
  margin: 1rem auto;
  margin-bottom: 0;
  font-size: 1.05rem;
  cursor: pointer;
  padding: 0.3rem 0.85rem;
  display: block;
  background-color: #0e73e7;
  color: #fff;
  border: none;
  border-radius: 5px;
}
.modeBtn {
  margin-bottom: 1rem;
  padding: 0.25rem 1rem;
  margin-right: 1rem;
  font-size: 1.1rem;
  cursor: not-allowed;
  background-color: #c8c9ca;
  color: #f7f7f7;
  border: none;
  border-radius: 4px;
}
.modeBtnActive {
  cursor: pointer;
  background-color: rgb(87, 164, 252);
  color: #fff;
}
.modeBtnEnabled {
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.3);
  background-color: #006ae4;
}
