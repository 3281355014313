.container {
  padding: 1rem;
}
.link {
  margin-bottom: 1rem;
  display: flex;
  font-weight: 500;
  font-family: 'Roboto';
}

.linkIcon {
  margin-right: 0.5rem;
}
