.blogs_Container{
    display: flex;
    justify-content: space-between;
}



.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    margin-top: 20px;
  }
  
  .pagination > li {
    background: #fff;
    /* border: 2px solid #666; */
    padding: 10px 15px;
    border-radius: 4px;

    /* border-radius: 50%; */
    /* height: 45px;
    width: 45px; */
    position: relative;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .paginationItem span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .previous,
  .next {
    background: #fff;
    border: none;
    padding: 10px !important;
    color: blue;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.4);
    margin: 0 10px;
    cursor: pointer;
  }
  
  .pagination .active {
    border: 1px solid #888;
    color: #888;
    pointer-events: none;
  }
  
  .previous.disabled,
  .next.disabled {
    pointer-events: none;
    box-shadow: none;
    color: #999;
  }