.sendSurvey {
  &__container {
    padding: 1.5rem;
    font-family: Roboto;
  }
  &__title {
    font-size: 1.4rem;
    text-align: center;
  }
  &__label {
    & span {
      cursor: pointer;
      color: rgb(18, 111, 250);
      font-weight: 500;
    }
    font-size: 1.1rem;
    margin: 1rem 0;
  }
  &__filter {
    margin: 1rem 0;
    & input {
      font-size: 1rem;
      padding: 0.5rem 0.85rem;
      border-radius: 4px;
      border: none;
      box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
        0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    }
  }
  &__selectedModal {
    background: #fff;
    font-family: Roboto;
    border-radius: 10px;
    &-label {
      font-size: 1.4rem;
    }
    width: 30rem;
    padding: 1.2rem;
    max-height: 90vh;
  }
  &__selectedList {
    margin-top: 0.25rem;
    font-size: 1.05rem;
    max-height: 55vh;
    overflow-y: auto;
    padding: 1rem;
    box-shadow: inset 1px 2px 3px rgba(0, 0, 0, 0.2);
  }
  &__sendBtn {
    margin: 1rem auto;
    margin-bottom: 0;
    display: block;
    width: 8rem;
    font-size: 1.1rem;
    padding: 0.35rem 0.8rem;
    text-align: center;
    background-color: rgb(18, 111, 250);
    color: #fff;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    cursor: pointer;
  }
}
.sendSurveyOverlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.37);
  z-index: 999;
}
.sendSurveyModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
