.container {
  padding: 1.5rem;
}
.title {
  font-size: 1.3rem;
  text-transform: capitalize;
  font-weight: 600;
  font-family: 'Roboto';
  margin-bottom: 0.75rem;
}
