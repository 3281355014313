.formGroup {
  margin-bottom: 1.25rem;
}
.container {
  padding: 1.5rem;
  border-radius: 15px;
  background-color: #fff;
  font-family: Roboto;
  max-height: 90vh;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track of the scrollbar */
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px; /* Rounded corners for the track */
  }

  /* Handle of the scrollbar */
  &::-webkit-scrollbar-thumb {
    background: #888; /* Grey color */
    border-radius: 10px; /* Rounded corners for the handle */
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darken the grey handle on hover */
  }

}
.title {
  font-weight: 600;
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.formLabel {
  font-weight: 600;
  font-size: 16px;
  color: #40444f;
  display: block;
  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #888888;

    
  }
}
.formCards {
  display: flex;
  flex-wrap: wrap;
}
.formCard {
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #297bca;
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  margin-top: 0.5rem;
  margin-right: 0.75rem;
  padding: 0.24rem 1.1rem;
  color: #297bca;
}
.formCardActive {
  color: #fff;
  background-color: #297bca;
}
.formSelect {
  margin-top: 0.5rem;
  z-index: 24;
  position: relative;
}

.formTextArea {
  background: #ffffff;
  box-shadow: 0px 1px 7px rgba(136, 136, 136, 0.25);
  padding: 0.6rem 1.3rem;
  display: block;
  width: 100%;
  border-radius: 12px;
  border: none;
  &::placeholder {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height */

    color: #cccccc;
  }
  &:focus {
    outline: none;
  }
}
.formTextAreaContainer {
  position: relative;
  margin-top: 0.5rem;
}
.formTextareaLength {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-weight: 300;
  font-size: 14px;
  color: #cccccc;
}
.row {
  display: flex;
  justify-content: space-between;
  .formGroup {
    width: 48%;
  }
}
.rowMain {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 991px) {
    display: block;
    .left {
      width: 100%;
      margin-bottom: 1rem;
    }
    .right {
      width: 100%;
    }
  }
}
.buttons {
  display: flex;
  justify-content: space-around;
}
.button {
  background: #297bca;
  border: 1px solid #8fb7ff;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  width: 12rem;
  height: 2.3rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1rem;
  color: #ffffff;
  cursor: pointer;
}
.nextPage {
  cursor: pointer;
  background: #297bca;
  border: 1px solid #8fb7ff;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  width: 12rem;
  height: 2.3rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1rem;
  color: #ffffff;
}
.prevPage {
  color: #297bca;
  background: #fff;
  border: 2px solid #297bca;
  border-radius: 4px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  display: flex;
  width: 12rem;
  height: 2.3rem;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  margin-top: 1rem;
}

.planSwitch {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  display: flex;
  line-height: 20px;
  color: #222222;
}
.planSwitchItem {
  cursor: pointer;
}
.planSwitchComp {
  background: #f9f6ff;
  border: 0.9px solid #297bca;
  border-radius: 21px;
  width: 27px;
  position: relative;
  height: 12px;
  margin: 0 0.5rem;
  cursor: pointer;
}
.planSwitchCompSwitch {
  width: 16px;
  height: 16px;
  position: absolute;
  left: -3px;
  transition: all 0.3s;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #297bca;
}
.planSwitchCompSwitchActive {
  transform: translate(100%, -50%);
}
.planSwitchRow {
  display: flex;
  justify-content: flex-end;
  max-width: 26rem;
  margin-bottom: 0.5rem;
}
.planSwitchLabel {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
  text-align: right;
  display:flex;
  align-items: center;
  color: #6a6a6b;
  gap:20px;
  margin-right: 1rem;

  .delete {
    cursor: pointer;
  }
}
.planSwitch {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #222222;
}
.planSwitchItem {
  cursor: pointer;
}
.planSwitchComp {
  background: #f9f6ff;
  border: 0.9px solid #297bca;
  border-radius: 21px;
  width: 27px;
  position: relative;
  height: 12px;
  margin: 0 0.5rem;
  cursor: pointer;
}
.planSwitchCompSwitch {
  width: 16px;
  height: 16px;
  position: absolute;
  left: -3px;
  transition: all 0.3s;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  background: #297bca;
}
.planSwitchCompSwitchActive {
  transform: translate(100%, -50%);
}
.learnMore {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  cursor: pointer;
  margin-top: 0.25rem;
  color: #40444f;
}
.planModal {
  border-radius: 14px;
  background-color: #fff;
  padding: 1.5rem;
  font-family: Poppins;
  width: 28rem;
}
.planModalItem {
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(56, 159, 255, 0.48);
  border-radius: 12px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  color: #297bca;
  padding: 1rem;
  align-items: center;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
}
.planModalBuyNowButton {
  background: #297bca;
  box-shadow: 0px 2px 4px rgba(47, 47, 47, 0.25),
    inset 0px 2px 5px rgba(255, 255, 255, 0.25);
  border-radius: 30px;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  /* identical to box height */

  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  border: none;
  margin: 1.5rem auto;
  margin-bottom: 0;

  cursor: pointer;
  height: 3rem;
  color: #ffffff;
}
.planModalTitle {
  font-weight: 600;
  font-size: 32px;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 1.25rem;
  color: #297bca;
}
.planModalSubTitle {
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  line-height: 21px;

  /* identical to box height */

  text-transform: uppercase;

  color: #000000;
}
.formLabelRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
  
.industrySpecial {
  display: flex;
  justify-content: space-between;
  align-items: center;
}


.screeningGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 5px;

  .AddQuestion {
    font-weight: 600;
  font-size: 16px;
  text-align: center;
  line-height: 21px;
    text-align: left;
    color: #40444f;
    width: fit-content;
    cursor: pointer;
  }

  .formLabel {
    //styleName: Medium/Text/TMedium1;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;

    color: #626A72;

  }



  .ques {
    display: flex;
    flex-direction: column;

    .quesLabel {
      //styleName: Medium/Text/TMedium1;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: left;

      color: #626A72;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;





      .planSwitchRow {
        display: flex;
        justify-content: flex-end;
        max-width: 26rem;
        margin-bottom: 0.5rem;

        .planSwitch {
          display: flex;
          align-items: center;
          font-weight: 600;
          font-size: 13px;
          line-height: 20px;
          color: #222222;

          .planSwitchComp {
            background: #C7C7CC;
            // border: 0.9px solid #297bca;
            border-radius: 21px;
            width: 30px;
            position: relative;
            height: 16px;
            margin: 0 0.5rem;
            cursor: pointer;

            .planSwitchCompSwitch {
              width: 13px;
              height: 13px;
              position: absolute;
              left: 2px;
              transition: all 0.3s;
              top: 50%;
              transform: translateY(-50%);
              border-radius: 50%;
              background: #FFFFFF;
              box-shadow: 0px 2px 8px 0px #00000029;
            }

            .planSwitchCompSwitchActive {
              transform: translate(100%, -50%);
              // background-color: #297bca;
            }
          }

          .planSwitchCompActive {
            background-color: #297bca;
            // border: 0.9px solid #297bca;
          }
        }
      }

      .planSwitchLabel {
        //styleName: Medium/Text/TMedium1;
        font-family: Poppins;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: left;

        color: #000000BA;
      }
    }
  }
}