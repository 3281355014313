.container {
    width: 400px;
    padding: 20px;
    display: flex;
    font-family: 'Poppins';
    flex-direction: column;
    gap: 20px;
  }
  
  .heading {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .loaderContainer {
    padding: 0 -65px;
  }
  .subHeading {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  
  .checkpoints {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .checkpoints ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .checkpoints li {
    font-size: 14px;
    margin-bottom: 5px;
  }
  
  .boostType {
    margin-bottom: 20px;
  }
  
  .advancedOptions {
    margin-bottom: 20px;
  }
  
  .inputField {
    margin-bottom: 15px;
  }
  
  .boostButton {
    align-self: flex-end;
  }
  