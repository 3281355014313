.container {
  font-family: Poppins;
}
.modalContainer {
  background-color: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  border-radius: 21px;
  width: 25rem;
  font-family: Poppins;
  max-width: 96vw;
  padding: 1.5rem;
}
.img {
  margin: 1rem 0;
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.txt2 {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: -0.5rem;
  text-align: center;

  color: #389fff;
  font-weight: 500;
  font-size: 13px;
  color: #888888;
}

.uploadItem {
  display: flex;
  border: 2px solid #389fff;
  filter: drop-shadow(0px 0.5px 6px rgba(165, 165, 165, 0.25));
  height: 3.2rem;
  overflow: hidden;
  transition: all 0.3s;
  border-radius: 15px;
}
.uploadItemError {
  border-color: #f23342;
}
.uploadItemField {
  padding: 0.3rem 0;
  display: flex;
  width: 100%;
}
.uploadItemLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 5rem;
  transition: all 0.3s;
  height: 100%;
  background-color: #389fff;
}
.uploadItemError .uploadItemLabel {
  background-color: rgba(242, 51, 66, 0.5);
}
.uploadItemValue {
  height: 100%;
  width: calc(100% - 5rem);
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* identical to box height */

  color: #585858;
}
.uploadItemButton {
  height: 100%;
  display: flex;
  cursor: pointer;
  align-items: center;
  color: #389fff;
  border-left: 1px solid #389fff;
  justify-content: center;
  width: 5rem;
  font-weight: 600;
  font-size: 12px;
  transition: all 0.3s;
}
.uploadItemError .uploadItemButton {
  color: #f23342;
  border-color: #f23342;
}
.uploadItemValue {
  padding: 0 0.85rem;
}
.uploadButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #585858;
  border-radius: 10px;
  height: 2.4rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */

  color: #585858;
}
.uploadButtonTxt {
  margin-top: 0.75rem;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height */

  color: #000000;
}
.uploadButtonError {
  font-weight: 500;
  margin-top: 0.5rem;
  font-size: 12px;
  color: #f23342;
}
.modalHeading {
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  text-align: center;

  color: #40444f;
}
.modalHeadingBlue {
  color: #389fff;
}
.actionButton {
  background: #389fff;
  box-shadow: 0px 2px 4px rgba(47, 47, 47, 0.25),
    inset 0px 2px 5px rgba(255, 255, 255, 0.25);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin-top: 1.5rem;
  height: 2.8rem;
  cursor: pointer;
  border: none;
}

.uploadItemAlternate {
  display: flex;
  width: 20rem;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #bbb8b8;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  /* identical to box height */
  height: 2.6rem;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
}
.uploadItemAlternateText {
  width: calc(100% - 4rem);
  padding: 0 1rem;
}

.uploadItemAlternateButton {
  background: #e7e6e6;
  border: 1px solid #bdb4b4;
  border-radius: 13px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
}
.uploadButtonTxt2 {
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height */
  margin-top: 0.5rem;
  color: rgba(0, 0, 0, 0.45);
  margin-left: 1rem;
}
.uploadItemUpdatedOn {
  font-weight: 300;
  margin-top: 0.2rem;
  font-size: 11px;
  line-height: 15px;
  color: #575454;
}
