.InventoryManagment {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 20px;

    & > .header {
        color: #297bca;
        text-align: center;
    }

    & > .mainContent {
        width: 100%;
        display: flex;
        gap: 10px;

        & > * {
            flex-grow: 1;
            flex-basis: 0;
        }

        & > .filterDivs {
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            padding: 10px;

            gap: 15px;
            margin-bottom: 20px;

            max-width: 50%;

            & > * {
                display: flex;
                flex-direction: column;
                gap: 10px;

                & > .topCont {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    align-items: end;
                }

                & > input {
                    width: 100%;
                    padding: 0.5rem;
                    border: 1px solid #ccc;
                    border-radius: 4px;
                    font-family: 'Poppins', sans-serif;
                    &:hover,
                    &:focus {
                      border-color: #297bca;
                    }
                }
            }

            & > .buttonGrp {
                // width: fit-content;
                display: flex;
                flex-direction: row;

                & > .goButton {
                    background-color: #2061a0;
                    color: #fff;
                    font-size: 1rem;
                    margin: auto;
                    padding: 0.8rem 2rem;
                    border: none;
                    border-radius: 82px;
                    font-family: "Poppins", sans-serif;
                    width: 100%;
                    max-width: 400px;
                    cursor: pointer;
                    transition: background-color 0.3s;

                    &:disabled {
                        background-color: rgba(0, 0, 0, 0.12);
                    }
                }
            }
            
            & > .TotalEntriesInfo {
                color: #297bca;
                padding: 16px;
                font-size: 1.25rem;
                text-align: center;
                font-weight: bold;
                border-radius: 4px;
                margin-bottom: 16px;
                background-color: #fff;

                cursor: pointer;
            }

            @media (max-width: 500px) {
                max-width: 100%;
            }
        }

        @media (max-width: 800px) {
            flex-direction: column;
        }
    }

}


.inputFields {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    &:hover,
    &:focus {
      border-color: #297bca;
    }
}

.FilterField {
    & > h6 {
        font-family: "Poppins", sans-serif;
        color: #666;
        margin-bottom: 0.25rem;
        margin: 0;
    }
}

.formCards {
    display: flex;
    flex-wrap: wrap;
}
.formCard {
    background: #ffffff;
    cursor: pointer;
    border: 1px solid #297bca;
    border-radius: 4px;
    font-weight: 500;
    font-size: 14px;
    margin-top: 0.5rem;
    margin-right: 0.75rem;
    padding: 0.24rem 1.1rem;
    color: #297bca;
}
.formCardActive {
    color: #fff;
    background-color: #297bca;

    display: flex;
    align-items: center;
}

.skillList {
    display: flex;
    flex-wrap: wrap;
}