@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

.container {
  margin-top: 0.5rem;
  font-size: small;
  font-family: 'Poppins', sans-serif;
  padding: 1.5rem;
  // background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: auto;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  font-family: 'Poppins', sans-serif;
  margin-bottom: 1rem;
  color: #297bca;
  text-align: center;
}

.formRow {
  display: flex;
  gap: 2rem;
}
.labelText {
  display: block;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: #666;
  margin-bottom: 0.25rem;
}

.labelContent {
  display: flex;
  align-items: center; /* Ensures vertical alignment is centered */
  font-family: 'Poppins', sans-serif;
  font-size: 0.8rem;
  color: #666;
  gap: 8px; /* Adjust this value based on your design preference */
}

.inputGroup {
  flex: 1;
  margin-bottom: 0.5rem;
  position: relative;

  label {
    display: block;
    font-family: 'Poppins', sans-serif;
    color: #666;
    margin-bottom: 0.25rem;
  }

  input,
  textarea,
  .react-select__control {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-family: 'Poppins', sans-serif;
    &:hover,
    &:focus {
      border-color: #297bca;
    }
  }

  textarea {
    min-height: 100px;
  }

  .required {
    color: red;
  }

  .error {
    position: absolute;
    color: red;
    font-size: 0.75rem;
    bottom: -20px;
  }
}

.addBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  .saveButton {
    background-color: #297bca;
    color: #fff;
    font-size: 1rem;
    margin: 1rem;
    padding: 0.4rem 1rem;
    border: none;
    border-radius: 82px;
    font-family: 'Poppins', sans-serif;
    width: 100%;
    max-width: 400px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover {
      background-color: darken(#297bca, 10%);
    }
  }
}
.submitButton {
  background-color: #297bca;
  color: #fff;
  font-size: 1rem;
  margin: auto;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 82px;
  font-family: 'Poppins', sans-serif;
  width: 100%;
  max-width: 400px;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: darken(#297bca, 10%);
  }
}

.phoneInput,
.currencyInput {
  display: flex;
  gap: 1rem;
}

.countrySelect,
.currencySelect {
  width: 30%;
}

.drawerContent {
  width: 600px;
  padding: 20px;
}

.updateGroup {
}
.remarks__container {
  margin-top: 20px;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.remarks-header {
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 10px;
}

.remark__item {
  margin-bottom: 10px;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.remark__admin-name {
  font-weight: bold;
}

.remark__text {
  margin-top: 5px;
  font-weight: 300;
}

.remark__timestamp {
  float: right;
  font-size: 0.8em;
  color: #999;
  margin-bottom: 5px;
}

.remark-input-container {
  margin-top: 7px;
  display: flex;
  flex-direction: column;
}

.remark-input-box {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding: 10px;
  margin-top: 5px;
}

.remark-input {
  flex-grow: 1;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 1em;
  outline: none;
  resize: none;
}

.send-button {
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 10px;
}

.send-button:hover {
  opacity: 0.8;
}

.remark-input:focus {
  border: 1px solid #297bca;
}

.next-follow-up {
  margin-top: 2px;
  font-size: 0.9em;
  color: #666;
}

.next-follow-up-date {
  font-weight: bold;
}

.radioGroup {
  display: flex;
  gap: 1rem; // Creates a space between the radio buttons
  align-items: center; // Aligns the radio buttons and labels vertically

  input[type='radio'] {
    background-color: #fff;
    border: 2px solid #ccc;
    cursor: pointer;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      border-color: #297bca; // Darken border on hover
    }
  }

  label {
    font-size: 1rem;
    color: #666; // Matching label color
    font-family: 'Poppins', sans-serif; // Ensuring the font is consistent
    cursor: pointer; // Cursor pointer to indicate it's clickable
  }
}
