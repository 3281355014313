.addCampaign__text {
  font-size: 1.4rem;
  font-family: Roboto;
  margin-top: 2rem;
}
.addNewCampaign_container {
  font-family: Roboto;
  /* box-shadow: 1px 2px 6px rgb(0 0 0 / 16%); */
  width: 30rem;
  margin: 2rem 0;
  /* border-radius: 10px; */
  /* background-color: #fff; */
  /* padding: 1rem; */
}
.addNewCampaign_title {
  font-size: 1.4rem;
  color: #2656f5;
}
.addNewCampaign_label {
  font-size: 1.1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.addNewCampaign_input {
  width: 100%;
  font-size: 1.1rem;
  padding: 0.25rem;
  border-radius: 5px;
  border: 1px solid #999;
}
.addNewCampaign_input:focus {
  outline: none;
}
.addNewCampaign_btn {
  background-color: #2656f5;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 13rem;
  border: none;
  margin: 1rem auto;
  height: 2rem;
  cursor: pointer;
  box-shadow: 1px 2px 6px rgb(0 0 0 / 16%);
  font-size: 1rem;
  border-radius: 6px;
}
.campaign_link {
  color: #2656f5;
  font-weight: 500;
}
.addNewCampaign_error {
  text-align: center;
}
