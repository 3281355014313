.shinyText {
    /* We make the text itself transparent,
       so the gradient behind it shows through */
    color: revert-layer;
    -webkit-text-fill-color: transparent; /* for Safari/Chrome */
    
    color: rgb(41, 112, 188);

    /* Shiny gradient */
    background: linear-gradient(
      120deg,
      rgba(41, 112, 188, 1) 40%,
      rgba(41, 112, 188, .4) 50%, /* fully opaque white at center */
      rgba(41, 112, 188, 1) 60%
    );
    background-size: 200% 100%;
  
    -webkit-background-clip: text; /* text mask */
    background-clip: text;
    display: inline-block;
  
    /* The shiny animation */
    animation: shine 5s linear infinite;
  }
  
  /* Keyframes for the sliding gradient */
  @keyframes shine {
    0% {
      background-position: 100%;
    }
    100% {
      background-position: -100%;
    }
  }
  
  /* Disable the shiny animation if needed */
  .shinyText.disabled {
    animation: none;
  }
  