.marketingCampaign__title {
  font-size: 1.7rem;
  font-weight: 500;
  font-family: Roboto;
}
.marketingCampaign__row {
  display: flex;
  font-family: Roboto;
  margin-top: 2rem;
  margin-bottom: 1rem;
  align-items: center;
}
.marketingCampaign__text {
  font-size: 1.2rem;
}
.marketingCampaign__btn {
  margin-left: 1rem;
  border: none;
  background-color: #2656f5;
  font-weight: 500;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4rem;
  height: 2rem;
  cursor: pointer;
  box-shadow: 1px 2px 6px rgb(0 0 0 / 16%);
  font-size: 1rem;
  border-radius: 6px;
}
.marketingCampaign__select {
  width: 15rem;
  margin-left: 1rem;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;
  height: 100vh;
  background-color: rgba(59, 59, 59, 0.616);
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30rem;
  transform: translate(-50%, -50%);
}
.modal:focus {
  outline: none;
}
.campaign_modal {
  box-shadow: 1px 2px 6px rgb(0 0 0 / 16%);
  background-color: #fff;
  border-radius: 5px;
  padding: 1.5rem;
  font-size: 1.2rem;
  font-family: Roboto;
}
.campaign_modal_buttons button {
  padding: 0.25rem 1.25rem;
  box-shadow: 1px 2px 6px rgb(0 0 0 / 16%);
  font-size: 1.1rem;
  /* border: none; */
  cursor: pointer;
  border-radius: 5px;
}
.campaign_modal_buttons-cancel {
  background-color: #fff;
  border: 1px solid #000;
}
.campaign_modal_buttons-action {
  margin-left: 1rem;
  border: 1px solid #2656f5;
  background-color: #2656f5;
  color: #fff;
}
.campaign_modal_buttons {
  margin-top: 2rem;
}
.disable_enable {
  padding: 0.25rem 0.75rem;
  font-size: 1.05rem;
  box-shadow: 1px 2px 6px rgb(0 0 0 / 16%);
  background: #fff;
  border-radius: 5px;
  border: 1px solid #333;
  cursor: pointer;
}
.marketingCampaign__subtext {
  font-size: 1rem;
}
.marketingCampaign__subselect {
  width: 8rem;
  margin-left: 1rem;
}
