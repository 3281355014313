.pageContainer {
  background-color: #fafafa;
}
.container {
  font-family: Poppins;
  padding: 1rem 7rem;
}
.tabs {
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #0167ff99;
}
.tabsRow {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.createNewExpBtn {
  background: #ffffff;
  border: none;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.15);
  border-radius: 62px;
  font-weight: 600;
  font-size: 14px;
  padding: 0.75rem 2rem;
  color: #387eff;
}
.tab {
  margin-right: 3rem;
  padding: 0 0.25rem;
  position: relative;
  cursor: pointer;
  &::after {
    transition: all 0.3s;
    content: '';
    left: 0;
    position: absolute;
    bottom: -0.25rem;
    height: 2px;
    width: 100%;
    transform: scaleX(0);
    background-color: #0167ff;
    display: block;
  }
}
.tabActive {
  color: #0167ff;
  &::after {
    transform: scaleX(1);
  }
}
.cardsContainer {
  display: flex;
  margin-top: 2rem;
  flex-wrap: wrap;
}
.expCardOverlay {
  position: absolute;
  z-index: 1;
  top: 0;
  background-color: rgba(0, 0, 0, 0.199);
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 14.3717px;
  right: 100%;
}
.expCard {
  font-family: Poppins;
  position: relative;
  width: 420px;
  background: #ffffff;
  border-radius: 14.3717px 14.3717px 0 0;
  padding: 0.7rem;
  display: flex;

  //   align-items: stretch;
}
.expCardImg {
  box-shadow: 0px 3.83246px 19.1623px rgba(0, 0, 0, 0.25);
  border-radius: 13.4136px;
  overflow: hidden;
  //   height: 100%;
  width: 130px;
  min-width: 130px;
  img {
    width: 100%;
    height: 100%;
  }
}
.expDetails {
  margin-left: 1rem;
  width: 100%;
  display: flex;
  font-family: Poppins;
  flex-direction: column;
  justify-content: space-between;
}
.expUserRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.expUserRowLeft {
  display: flex;
  align-items: center;
}
.expUserImg {
  min-width: 32.58px;
  height: 32.58px;
  width: 32.58px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 0.5rem;
  background-color: #aaa;
  img {
    width: 100%;
  }
}
.expUserName {
  font-size: 13.5px;
}
.expUserScore {
  width: 35px;
  position: relative;
  margin-left: 0.5rem;
}
.menuIcon {
  cursor: pointer;
  position: relative;
}
@keyframes popupanimation {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.menuContainer {
  transform-origin: top right;
  animation: popupanimation 0.3s;
  position: absolute;
  top: 1.5rem;
  z-index: 2;
  right: 0;
  width: 14rem;
  //   border: 1px solid red;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.19);
  border-radius: 9px;
  border-top-right-radius: 0;
  padding: 0.5rem 0;
  background-color: rgb(235, 246, 255);
}
.menuItem {
  padding: 0.3rem 0.9rem;
  font-size: 14px;
  font-weight: 500;
  margin: 0.2rem 0;
  &:hover {
    background-color: rgb(210, 235, 255);
  }
}
.expUserScoreValue {
  position: absolute;
  top: 50%;
  color: #fff;
  left: 50%;
  font-size: 12px;
  font-weight: 500;
  transform: translate(-50%, -50%);
}
.expTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  margin-top: 0.5rem;
}
.expDesc {
  margin-top: 0.5rem;
  color: #444;
  //   font-weight: 300;
  font-size: 13px;
  line-height: 17px;
}
.expBtn {
  background: #ecf3ff;
  border-radius: 8px;
  box-shadow: 0px 0.958115px 3.83246px rgba(0, 0, 0, 0.15),
    inset 0px 0.958115px 11.4974px rgba(255, 255, 255, 0.25);
  border-radius: 10.5393px;
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  margin-top: 1rem;
  color: #387dff;
}

.loaderContainer {
  width: 19rem;
  margin: 2rem auto;
}

.negativeMsg {
  text-align: center;
  // width: 100%;
  padding: 6rem 4rem;
}
.buttonsContainer {
  display: flex;
  justify-content: space-around;
  padding: 0.6rem;
  background-color: #fff;
}
.acceptBtn {
  width: 5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 6px;
  background-color: #0167ff;
  cursor: pointer;
}
.rejectBtn {
  width: 5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ff3a2c;
  cursor: pointer;
  border: 1px solid #ff3a2c;
  border-radius: 6px;
  background-color: rgb(255, 236, 236);
}
.superContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0px 1.91623px 3.83246px rgba(0, 64, 255, 0.15),
    inset 0px 0.958115px 9.58115px rgba(0, 64, 255, 0.15);
  border-radius: 14.3717px 14.3717px 0 0;
  border-radius: 14.3717px;
  overflow: hidden;
  background-color: #fff;
}
